

import { Component, Input, OnInit } from "@angular/core";
import {
	AlertService,
	FinanceService,
	OrganizationService,
	PermissionService,
	makeElapsedTimeString,
	getElapsedTime,
} from "../../shared/services";
import { Organization, StripeProduct } from "../../shared/models";
import { isEmpty } from "lodash";
import retry from 'async-retry';
import { environment } from "../../../environments/environment";
import moment from "moment";
import { ConfirmationModal } from "../confirmation";
import { MatDialog } from "@angular/material/dialog";

const stripePortalLink = "https://billing.stripe.com/p/login/7sI2aIcO69VwdZS3cc";
const stripePortalStaging = "https://billing.stripe.com/p/login/test_00gaHQf8qcG81gIfYY";

interface Period {
	value: string;
	name: string;
}

const bailCodes = [200];
const productText = {
	"active": "Active Subscription",
	"paused": "Paused",
	"cancelled": "Cancelled" ,
	"non_renewing": "Not Renewing"
};

@Component({
	selector: "app-new-plan",
	templateUrl: "./new-plan.component.html",
	styleUrls: ["./new-plan.component.scss"],
})
export class NewPlanComponent implements OnInit {

	@Input("orgList") organizationList: Array<any> = [];

	public selectedOrganization: any;
	public selectedProduct: StripeProduct = null;
	public userIsAdmin: boolean = false;
	public isLoading: boolean = true;
	public isSubmitting: boolean = false;

	public periodOptions: any = [
		{ value: "month", name: "Monthly" },
		{ value: "year", name: "Annually" },
	];
	public periodMap: any =
		{month: "Monthly", year: "Annually"};
	public selectedPeriod: Period = this.periodOptions[1];
	public productList: any[] = null;
	public filteredProducts: any[] = null;

	constructor(
		private _alertService: AlertService,
		private _dialog: MatDialog,
		private _financeService: FinanceService,
		private _orgService: OrganizationService,
		private _permissionService: PermissionService
	) {
	}

	ngOnInit() {
		this.setup();
	}

	hasActiveOrgSub = (org: any = this.selectedOrganization): boolean =>
		org?.subscribed && org.subscription?.status === "active";

	isOrgProduct = (product: StripeProduct, org: any = this.selectedOrganization): boolean =>
		this.hasActiveOrgSub(org) && (product.id === org.subscription?.plan?.product);

	async setup() {

		await this.getProducts();

		await this.getOrganizations().then(selectedOrganization => {
			this.selectOrg(selectedOrganization);
		});

		this.isLoading = false;
	}

	async getProducts() {

		return retry(
			(bail) =>
				this._financeService
					.getPlans().then(({ prices, products } ) => {
						this.makeProductList(prices, products);
					}
				).catch(errorCode => {
					if (bailCodes.includes(errorCode)) {
						bail(new Error(errorCode));
					} else {
						console.warn('Plans list GET failed, retrying...');
						throw new Error(errorCode);
					}
				}),
			{
				retries: 5,
				minTimeout: 50
			}
		);
	}

	async getOrganizations() {

		if (isEmpty(this.organizationList)) {
			this.organizationList = await this._orgService.getList();
		}

		if (this.organizationList.length >= 1) {
			if (this._orgService._activeOrg?.id) {
				this.selectedOrganization = this.organizationList.find(x => x.id === this._orgService._activeOrg.id);
			}
			if (!this.selectedOrganization?.id) {
				this.selectedOrganization =  this.organizationList[0];
			}
		}

		return this.selectedOrganization;
	}

	makeProductList(prices, products) {

		const pricesObj = prices.reduce((acc, price) => {
			Object.assign(acc, {[price.id]: price});
			return acc;
		}, {});

		this.productList = products.reduce((acc, prod) => {
			if (prod.metadata?.display && prod.default_price) {
				let outProd = Object.assign({}, prod, {price: pricesObj[prod.default_price]});
				outProd.periodValue = outProd.price?.recurring?.interval;
				if (prod.metadata?.plan_features) {
					outProd.metadata.plan_features = prod.metadata.plan_features
						?.split(", ", )
						.map(x => x.replace(/['"]+/g, ''));
				}
				acc.push(outProd);
			}
			return acc;
		}, []);

		this.setProductsByPeriod(this.productList, this.selectedPeriod);
	}

	setProductsByPeriod(products = this.productList, period = this.selectedPeriod) {
		this.filteredProducts = products.filter(x => x.periodValue === period.value || this.isOrgProduct(x))
			.sort((a, b) => this.isOrgProduct(a) ? -1 : 1);
		this.selectedProduct = this.filteredProducts[0];
	}

	getProductStatusClass = ( org: Organization ) =>
		org.subscription.cancel_at ?
			"product-status-non_renewing" :
			"product-status-" + org.subscription.status;

	setSelectedProduct = (product: any) => this.selectedProduct = product;

	isSelectedProduct = (product: any) => this.selectedProduct?.id === product?.id;

	isSelectedPeriod = (period: Period) => this.selectedPeriod === period;

	async selectOrg(org: Organization) {
		this.isLoading = true;
		await this.checkUserIsAdmin(org);
		this.setProductsByPeriod();
		if (this.selectedProduct?.periodValue) {
			this.changePeriod(this.periodOptions.find(period => period.value === this.selectedProduct.periodValue));
		}
		this.isLoading = false;
	}

	async checkUserIsAdmin(org: Organization) {
		this.userIsAdmin = await this._permissionService.compareToOrgPermissionsPromise(org, "admin");
	}

	getProductStatusText = ( org: Organization ) =>
		org.subscription?.cancel_at ?
			this.makeCancelText(org.subscription.cancel_at) :
			productText[org.subscription?.status];

	makeCancelText(cancelTime: number) {
		return cancelTime ? `Ends in ${getElapsedTime(moment(cancelTime * 1000)).timeString}` : null;
	}

	getProductTooltip(cancelTime: number) {
		return new Date(cancelTime * 1000);
	}

	async submit(product: any) {
		this.isSubmitting = true;
		if (this.isOrgProduct( product )) {
			await this.createPortal();
		} else {
			if (this.hasActiveOrgSub()) {
				const confirmOptions = {
					title: `Change Plan`,
					text: `Are you sure you want to change your plan to ${product.name}?`,
					buttonText: "Yes",
					showCancel: true,
					cancelText: "No"
				};
				const dialogRef = this._dialog.open(ConfirmationModal, {data: confirmOptions})
				dialogRef.afterClosed().subscribe(rtn => {
					if (rtn) {
						this.changePlan(product);
					}
					this.isSubmitting = false;
				});
			} else {
				await this.createCheckout(product);
			}
		}
	}

	async ensureBilling() {
		if (!this.selectedOrganization?.stripe_id) {
			await this._orgService.ensureBilling(this.selectedOrganization.id).catch(e => {
				console.error(e);
				this.isSubmitting = false;
				this._alertService.notify("There was an issue with your organization, please contact support.", "error");
				return this.updateOrgByID();
			});
		}
	}

	async updateOrgByID(orgId: number = this.selectedOrganization.id) {
		this.isLoading = true;
		return this._orgService.getById(orgId).then(rtnOrg => {
			const orgIndex = this.organizationList.findIndex(org => org.id === rtnOrg.id);
			if (orgIndex > 0) this.organizationList[orgIndex] = rtnOrg;
			this.isLoading = false;
		}).catch(err => {
			this.isLoading = false;
			this._alertService.notify("Could not update your subscription, please close and reopen this view.", "error");
			console.error(err);
		});
	}

	async createPortal() {
		await this._financeService.createPortal({
			return_url: window.location.href,
			customer: this.selectedOrganization.stripe_id
		}).then(rtn => {
			if (rtn.url) {
				window.open(rtn.url, "_blank");
			}
		}).catch(e => {
			console.error(e);
			this._alertService.notify("Could not open Stripe Portal, please try again.", "error");
		});

		this.isSubmitting = false;
	}

	async createCheckout(product: any) {

		await this.ensureBilling();

		if (this.selectedOrganization?.stripe_id) {
			await this._financeService.createCheckout({
				line_items: [{ price: product.default_price, quantity: 1 }],
				success_url: window.location.href,
				cancel_url: window.location.href,
				customer: this.selectedOrganization.stripe_id
			}).then(rtn => {
				if (rtn.url) {
					window.open(rtn.url, "_blank");
				}
			}).catch(e => {
				console.error(e);
				this._alertService.notify("Could not open Checkout, please try again.", "error");
			});
		} else {
			this._alertService.notify("Your team is missing payment information, please try again.", "error");
		}
		this.isSubmitting = false;
	}

	async changePlan(product: any) {

		await this.ensureBilling();

		const price_id = product?.price?.id;
		const item_id = this.selectedOrganization?.subscription?.items?.data[0]?.id;

		if (price_id && item_id && !this.isLoading) {
			const stripePlan = { item_id, price_id };
			await this._financeService.updatePlan(stripePlan).then(() => {
				setTimeout(() => {
					this.isSubmitting = false;
					this._alertService.notify("Your plan has successfully updated, the changes might not be reflected immediately.", "success");
					this.updateOrgByID();
				}, 2000);
			}).catch(e => {
				console.error(e);
				this._alertService.notify("Could not change your plan, please try again.", "error");
			});
		} else if (!(this.isLoading)) {
			this.isSubmitting = false;
			this._alertService.notify("Could not change your plan, if this persists please reach out to support.");
			console.error(`Missing ${price_id ? 'item_id' : 'price_id'}`)
		}
	}

	getPlanSelectText( product: any, org: Organization ) {
		const MATCH_KEY = true as any;
		const matcher = {
			[MATCH_KEY]: "Change Plan",
			[this.isOrgProduct( product, org ) as any]: "Manage Subscription",
			[!this.hasActiveOrgSub( org ) as any]: "Select Plan",
		};
		return matcher[MATCH_KEY];
	}

	isChangePlan(product, org= this.selectedOrganization) {
		return this.hasActiveOrgSub(org) && !this.isOrgProduct(product, org);
	}

	changePeriod(period: Period) {
		this.selectedPeriod = period;
		this.setProductsByPeriod();
	}
}
