import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { detect } from "detect-browser";
import { isEmpty, isNil } from "lodash";

import {
	ConfirmationModal,
	InformationModal,
	ErrorModal,
} from "@app/components";

import { Project } from "../../shared/models";
import {
	ProjectService,
	TitleService,
	ModelService,
	AuthenticationService,
	ImageService,
	OrganizationService,
	PermissionService, UtilsService
} from "../../shared/services";
import { LAYER_TYPES_2D, LAYER_TYPES_3D } from "../viewer";
import { Annotations } from "../viewer/classes/annotate";

import { MAP_OPTIONS } from "./map.options";
import { MapRendererComponent } from "./renderer/map.renderer.component";
import {
	sortByNewestToOldest,
	isValidModel,
	parseLayersFromModel,
	isValidAnnotation,
	getAnnotationMapType,
	isLabelingAnnotation,
	calculateImageGroupCenter,
	randomColor,
} from "./utils/map.utils";
import { DATA_FILTERS, MapSidebarComponent } from "./sidebar/map.sidebar.component";
import { MapHeaderComponent } from "./header/map.header.component";
import { MapToolsComponent } from "./tools/map.tools.component";
import { ElevationLegendComponent } from "./elevation/elevation.legend.component";
import { LAYER_FILE_TYPE } from "./utils/map.utils";
import { AnnotationType } from "../viewer/classes/measure";
import { LabelTools } from "./labeling/map.labelTools";

enum LAYER_GROUP_STATUS {
	Loading,
	Error,
	Processing,
	Rendered
};

@Component({
	selector: "app-map",
	styleUrls: ["./map.component.scss"],
	templateUrl: "./map.component.html",
})
export class MapComponent implements OnInit {

	public loading = true;
	public browser = "";

	public threeDMode = true;
	public hidden = false;

	/* Project Info */
	public project: Project;
	public models: Array<any> = [];
	public annotations: Array<any> = [];
	public imageGroups: Array<any> = [];
	public labelSets: Array<any> = [];
	public activeOrg;

	public mapPermissions = {
		canExport: false,
		canAnnotate: false,
		canLabel: false
	};

	public labelTools: LabelTools;

	projection: string;

	public publicView = false;
	public isMobile: boolean = false;

	@ViewChild('sidebar') sidebar: MapSidebarComponent;
	@ViewChild('header') header: MapHeaderComponent;
	@ViewChild('legend') legend: ElevationLegendComponent;
	@ViewChild('toolbar') toolbar: MapToolsComponent;
	public renderer: MapRendererComponent;

	_initialModelToShow = null;
	_initialLayerToShow = null;
	_lastModelColorUsed = -1;
	_lastImageGroupColorUsed = -1;

	_modelsRendered = false;
	_modelsLoaded = false;
	_annotationsLoaded = false;
	_annotationsRendered = false;
	_annotationsToLoad = [];

	// Comparison Mode Props
	public comparisonModeActivated = false;
	public comparisonLayers =  {
		left: null,
		right: null,
		previousSide: 0,
		range: '50%'
	};

	// Options
	public qualitySelection: any = MAP_OPTIONS.QUALITY_OPTIONS[1];
	public lightBackground = false;
	public selectedUnits = MAP_OPTIONS.DISPLAY_UNITS[0];

	// Debug Options
	public isDeveloper = false;
	public debugDetails = false;
	public debugImages = false;
	public debug3DTiles = false;

	public showOutOfBoundsError: boolean = false;
	public orientation: number = 0;
	public heading: number = 0;
	public userCoords: {latitude, longitude, latlng?, orientation, heading};

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _dialog: MatDialog,
		private _authService: AuthenticationService,
		public _orgService: OrganizationService,
		public _permissionService: PermissionService,
		private _projectService: ProjectService,
		private _modelService: ModelService,
		private _titleService: TitleService,
		private _utilsService: UtilsService,
		private _imageService: ImageService
	) {

		this.isMobile = this._utilsService.getIsMobile();
	}

	ngOnInit() {
		this.isDeveloper = this._authService.isDeveloper;

		this.setup();
	}

	setup() {
		if (!this.hasWebgl2()) {
			this.browser = this.detectSupportedBrowser();
			alert("no webgl 2 support");
			// TODO
			return;
		}

		const project_id = parseInt(
			this._route.snapshot.paramMap.get("project")
		);

		this._route.queryParams.subscribe((params) => {
			const public_share_guid = params["s"];
			if(params["model"])
				this._initialModelToShow = params["model"];
			if(params["layer"])
				this._initialLayerToShow = params["layer"];

			if (project_id) {
				this.loadProject(project_id);
			} else if (public_share_guid) {
				this._modelService.getPublicShare(public_share_guid).then(rtnProject => {
					this.publicView = true;
					this.handleProject(rtnProject);
				} ).catch( err => {
					console.error( err );
					this.handleMissingShare();
				} );
			} else {
				this._router.navigateByUrl("/projects/list");
			}
		});
		this.activeOrg = this._orgService._activeOrg;

		this.labelTools = new LabelTools(this);
	}

	loadProject(project_id): void {

		/*
		Thinking about the stages of the Map loading
		1 - page opens and should show some loading indicator
		2 - the Project loads - loading info goes away and Project level info slides in
			layers-list should be in a loading state
		3 - the models-list loads and populating in the layers-list
		4 - Models start loading and populating the layers-list with their layers
		5 - layers from the "1st" Model get rendered
		*/

		this._projectService.getByIdV2(project_id).then(
			(project) => {
				this.handleProject(project);
			},
			(error) => {
				console.warn("Project Load Error: ", error);
				if (error === 401) {
					this.handleUserAccessFail();
				}
			}
		);
	}

	async handleProject(project) {
		if (project && typeof project === "object") {
			this.project = project;
			this._titleService.setTitle(project.name);
			this.loading = false;
			this.activeOrg = this._orgService.getOrgFromList(project.organization_id);

			this.checkRolePermissions();

			this.models = [];
			this.imageGroups = [];

			if(!this.publicView) {
				this.imageGroups = await this._imageService.getBatchesByProjectId(project.id).catch(() => []) ?? [];

				this.imageGroups = await Promise.all(this.imageGroups.map(async (batch) => {
						const batchImages = await this._imageService.getBatchImagesV2(batch.id) ?? [];
						batch.images = batchImages.filter(image => {
							return image.trash === 0 && image.active === 1 && isNil(image.source_image_id)
						});
						batch.color = this.getImageGroupColor();
						batch.expanded = false;
						batch.center = calculateImageGroupCenter(batch);
						return batch;
					}).filter((batch) => {
						return !!batch;
					}));

				this.project.models = await this._modelService.getListV2({project_id: project.id}).catch(() => []);
			}

			// sort models by processed date
			this.project.models?.sort(sortByNewestToOldest);

			if (!this.project.models?.length && !this.imageGroups.length) {
				this.handleMissingAllData();
				return;
			}

			if(!this.publicView) this.loadAnnotations();

			if(this.project.models?.length) {
				await Promise.all(this.project.models.map(async (model) =>
					await this.loadModel(model)
				));
			}
			this.allModelsLoaded();
		} else {
			console.error(
				"User does not have access to Project. ERROR:",
				project
			);
			this.handleUserAccessFail();
		}

	}

	checkRolePermissions() {
		if(this.publicView || !this.activeOrg) {
			this.mapPermissions = {
				canExport: false,
				canAnnotate: false,
				canLabel: false
			};
			return;
		}

		const userId = this._authService.user.id;
		const processRole = this._permissionService.compareToOrgPermissions(this.activeOrg, 'process', userId);
		const adminRole = this._permissionService.compareToOrgPermissions(this.activeOrg, 'admin', userId);

		if (processRole || adminRole) {
			this.mapPermissions = {
				canExport: true,
				canAnnotate: true,
				canLabel: true
			};
		}
	}

	allModelsLoaded() {
		this._modelsLoaded = true;
		this.maybeRenderModels();
		this.checkAllModelsLayersForPotrees();
	}

	rendererReady(renderer): void {
		this.renderer = renderer;
		this.maybeRenderModels();
	}

	async maybeRenderModels() {
		if (this.renderer && !this._modelsRendered && this._modelsLoaded) {
			this._modelsRendered = true;
			await this.renderer.setupModels(this.models, this.projection);

			if(this.models.length > 0) {

				// remove unloaded layers
				this.models.forEach((model) => {
					model.layers = model.layers.filter((layer) => layer.loaded)
				});

				// sort models by processed date
				this.models?.sort(sortByNewestToOldest);

				// if there is no specified model, show the most recent
				let firstModelToShow = this.models.find(
					(model) => model.layers.length > 0
				);
				if (this._initialModelToShow) {
					firstModelToShow =
						this.models.find((model) => model.id == this._initialModelToShow) ??
						firstModelToShow;
				}
				firstModelToShow.expanded = true;
				let hasVisibleLayer = false;
				if(this._initialLayerToShow){
					const layer = firstModelToShow.layers.find(
						(layer) => layer.id.slice(1) === this._initialLayerToShow
					);
					if(layer){
						layer.visible = true;
						this.threeDMode = layer.mapType === "3D";
						this.renderer.updateLayerVisibility(layer);
						hasVisibleLayer = true;
					}
				}
				if (!hasVisibleLayer){
					// make one of the 2D layers visible, if they exist
					for (const type in LAYER_TYPES_2D) {
						const layer = firstModelToShow.layers.find(
							(layer) => layer.type === type
						);
						if (layer) {
							layer.visible = true;
							this.threeDMode = false;
							this.renderer.updateLayerVisibility(layer);
							break;
						}
					}
					if (!this.isMobile) {
						// make one of 3D layers visible, default to 3D view initially
						for (const type in LAYER_TYPES_3D) {
							const layer = firstModelToShow.layers.find(
								(layer) => layer.type === type
							);
							if (layer) {
								layer.visible = true;
								this.threeDMode = true;
								this.renderer.updateLayerVisibility(layer);
								break;
							}
						}
					}
				}
				this.renderer.focusOnModel(firstModelToShow);
			}
			else if(this.imageGroups.length > 0) {
				this.threeDMode = false;

				// show the first image group with a good center point
				const firstImageGroupToShow = this.imageGroups.find(
					(group) => group.center.lat != 0
				);

				if(firstImageGroupToShow) {
					this.sidebar.filterLayers(DATA_FILTERS.IMAGES);
					firstImageGroupToShow.expanded = true;
					firstImageGroupToShow.visible = true;
					this.renderer.updateImageVisibility(firstImageGroupToShow);
					if(firstImageGroupToShow.images.length)
						this.renderer.focusOnImage(firstImageGroupToShow.images[0])
				}
			}

			this.maybeRenderAnnotations();
		}
	}

	async loadModel(model) {

		if (!isValidModel(model)) {
			//model.status = model.status == "New" ? LAYER_GROUP_STATUS.Processing : LAYER_GROUP_STATUS.Error;
			//model.errorMessage = "Processing";
			//this.finishLoadingModel(model);
			return;
		}

		if(model.descriptors?.crs?.proj4) {
			this.projection = this.projection ?? model.descriptors?.crs?.proj4;

			if (this.projection !== model.descriptors.crs.proj4) {
				console.warn("Trying to load a Model which as a different projection than the other Models in the Project.");
				//model.status = LAYER_GROUP_STATUS.Error;
				//model.errorMessage = "Model is outside of the Project area";
				//this.finishLoadingModel(model);
				//return;
			}
		}

		// Model Layers-List stuff
		model.color = this.getModelColor();
		model.status = LAYER_GROUP_STATUS.Loading;
		model.expanded = false;
		model.layers = [];


		// load the deep-Model if it isnt already deep
		if(!model.geotiffs) {
			const deepModel = await this._modelService.getModelV2(model.id).catch(() => {model});
			if (deepModel) {
				model.potree_files = deepModel.potree_files ?? [];
				model.geotiffs = deepModel.geotiffs ?? [];
				model.cesium_files = deepModel.cesium_files ?? [];
				model.overlays = deepModel.overlays ?? [];
			}
		}
		parseLayersFromModel(model);
		if(model.layers.length > 0) {
			this.models.push(model);
			// sort models by processed date
			this.models?.sort(sortByNewestToOldest);
		}

		this.finishLoadingModel(model);

		// TODO - connect image batches
	}

	finishLoadingModel(model) {
		model.loading = false;
	}

	checkAllModelsLayersForPotrees() {
			const checkAllModelsLayers = this.models.flatMap((model) => {
				return model.layers
			}).some(layer => layer.fileType === LAYER_FILE_TYPE.POTREE);
		this.toolbar.hasElevationViewLayers = checkAllModelsLayers;
	}

	enterElevationMode(): void {
		this.header.showMessage("Entering Elevation Mode");
		this.toolbar.elevationModeActivated = true;
		this.sidebar.dataFilter = 'layers';
		this.renderer.setElevationMode(true);
		this.renderer.switchView_3D();
	}

	exitElevationMode() {
		this.header.showMessage("Exiting Elevation Mode");
		this.toolbar.elevationModeActivated = false;
		this.renderer.setElevationMode(false);
	}

	enterComparisonMode(): void {
		this.header.showMessage("Entering Comparison Mode");
		this.comparisonModeActivated = true;
		this.renderer.switchView_2D();

		const allGeotiffLayers = this.models.flatMap((model) => {
			return model.layers
		}).filter(layer => layer.fileType === "geotiffs");
		let layersToCompare = <any>[];
		allGeotiffLayers.forEach((layer) => {
			if(layer.visible) {
				if(layersToCompare.length < 2)
					layersToCompare.push(layer);
				else {
					layer.visible = false;
					this.renderer.updateLayerVisibility(layer);
				}
			}
		});

		if(layersToCompare.length < 2)
			allGeotiffLayers.forEach((layer) => {
				if(!layer.visible && layersToCompare.length < 2) {
					layer.visible = true;
					this.renderer.updateLayerVisibility(layer);
					layersToCompare.push(layer);
				}
			});

		this.comparisonLayers.left = layersToCompare[0] ?? null;
		this.comparisonLayers.right = layersToCompare[1] ?? null;

		this.renderer._twoDRenderer.compareLayers(this.comparisonLayers.left, this.comparisonLayers.right);
	}

	exitComparisonMode(): void {
		this.header.showMessage("Exiting Comparison Mode");
		this.comparisonModeActivated = false;
		this.clearComparisonLayers();
		this.renderer._twoDRenderer.exitComparisonMode();
	}

	clearComparisonLayers() {
		this.comparisonLayers.left = null;
		this.comparisonLayers.right = null;
		this.comparisonLayers.previousSide = 0;
	}

	async loadAnnotations() {
		Annotations.setProject(this.project);

		this._annotationsToLoad = [];
		const rawAnnotations = await Annotations.getList(this.project.id).catch(() => []);
		for(let annotation of rawAnnotations) {
			this.checkAnnotation(annotation);
		}
		this._annotationsLoaded = true;
		this.maybeRenderAnnotations();
	}

	checkAnnotation(annotation) {
		if(isValidAnnotation(annotation)) {
			annotation.expanded = false;
			annotation.mapType = getAnnotationMapType(annotation);
			if(isLabelingAnnotation(annotation)) {
				this.labelTools.loadLabelAnnotation(annotation);
			} else {
				this._annotationsToLoad.push(annotation);
			}
		} else {
			console.warn("Caught a bad annotation: ", annotation);
		}
	}

	maybeRenderAnnotations() {
		if(this._modelsRendered && this._annotationsLoaded && !this._annotationsRendered) {
			this._annotationsRendered = true;
			this.renderer.renderAnnotations(this._annotationsToLoad);
		}
	}

	getAllLayers(): any {
		return this.models.flatMap((group) => group.layers);
	}

	hasLayers(): boolean {
		return this.getAllLayers().length > 0;
	}

	getModelColor(): string {
		this._lastModelColorUsed++;
		if (this._lastModelColorUsed < MAP_OPTIONS.LAYER_GROUP_COLORS.length)
			return MAP_OPTIONS.LAYER_GROUP_COLORS[this._lastModelColorUsed];

		return randomColor();
	}

	getImageGroupColor(): string {
		this._lastImageGroupColorUsed++;
		if (this._lastImageGroupColorUsed < MAP_OPTIONS.LAYER_GROUP_COLORS.length)
			return MAP_OPTIONS.LAYER_GROUP_COLORS[this._lastImageGroupColorUsed];

		return randomColor();
	}

	toggleBackground(): void {
		this.lightBackground = !this.lightBackground;
		if (this.lightBackground) {
			this.renderer.setBackground(MAP_OPTIONS.BACKGROUND_COLORS.LIGHT);
		} else {
			this.renderer.setBackground(MAP_OPTIONS.BACKGROUND_COLORS.DARK);
		}
	}

	changeQualitySetting(): void {
		this.renderer.updateQuality(this.qualitySelection);
	}

	toggleDebugDetails(): void {
		this.debugDetails = !this.debugDetails;
	}

	toggleDebug3DTiles(): void {
		this.debug3DTiles = !this.debug3DTiles;
		this.renderer._threeDRenderer.setCesiumTileDebugging(this.debug3DTiles);
	}

	addAnnotation(annotation): void {
		this.annotations.push(annotation);
	}

	removeAnnotation(annotation): void {
		const index = this.annotations.indexOf(annotation);
		if (index > -1) {
			this.annotations.splice(index, 1);
		}
	}

	layerMissingCoordinates(layer): void {
		layer.missingCoordinateSystem = true;

		// check if all the Layers in the Model are missing their coordinates, if so, mark the Model as missingCoordinates
		layer.model.missingCoordinateSystem = layer.model.layers.every(layer => layer.missingCoordinateSystem);
	}

	hasWebgl2(): boolean {
		// WebGL2 detection code from Three.js - https://github.com/mrdoob/three.js/blob/master/examples/jsm/WebGL.js
		try {
			const canvas = document.createElement("canvas");
			return !!(
				window.WebGL2RenderingContext && canvas.getContext("webgl2")
			);
		} catch (e) {
			return false;
		}
	}

	detectSupportedBrowser(): string {
		const detectedBrowser = detect();
		const versionNum = parseInt(detectedBrowser.version);

		switch (
			detectedBrowser.name //https://caniuse.com/webgl2
		) {
			case "chrome":
				return versionNum >= 43 ? detectedBrowser.name : "";
			case "safari":
				return versionNum >= 11 ? detectedBrowser.name : "";
			case "firefox":
				return versionNum >= 25 ? detectedBrowser.name : "";
			case "edge-chromium":
				return versionNum >= 79 ? detectedBrowser.name : "";
			case "ios":
				return versionNum >= 12 ? "safari" : "";
			case "opera":
				return versionNum >= 43 ? detectedBrowser.name : "";
		}
		return null;
	}

	/* Error Dialogs */
	handleMissingShare(): void {
		this.hidden = true;
		const dialogRef = this._dialog.open(ConfirmationModal, {
			data: {
				buttonText: "Return to Mapware",
				showCancel: false,
				text: "The model link is incorrect or has expired. Please check the URL or contact support if you believe this is an error.",
				title: "Model not found",
			},
		});
		dialogRef.afterClosed().subscribe(() => {
			this._router.navigateByUrl("/");
		});
	}

	handleMissingAllData(): void {
		this.hidden = true;
		const dialogRef = this._dialog.open(InformationModal, {
			data: {
				buttonText: "Return to the Project",
				text: "There are no Layers or Images in this Project. Please upload or process Images or Layers to see them in the Map.",
				title: "No Data To Show",
			},
		});
		dialogRef.afterClosed().subscribe(() => {
			this._router.navigateByUrl("/projects/view/" + this.project.id);
		});
	}

	handleUserAccessFail(): void {
		this.hidden = true;
		const dialogRef = this._dialog.open(InformationModal, {
			data: {
				buttonText: "Return to the home page",
				text: "You do not have permission to view this model. Please contact your administrator or support if you believe this is a mistake.",
				title: "Error",
			},
		});
		dialogRef.afterClosed().subscribe(() => {
			this._router.navigateByUrl("/projects/list");
		});
	}

	handleViewerCrash(): void {
		this.hidden = true;
		const dialogRef = this._dialog.open(ErrorModal, {
			data: {
				couldRefresh: true,
				defaultActionText: "Return to the project",
				error: "WebGL context lost",
				supportPage: {
					path: "s/article/faq-browser-could-not-initialize-webgl",
					title: "WebGL Troubleshooting",
				},
			},
		});
		dialogRef.afterClosed().subscribe((shouldRefresh) => {
			shouldRefresh
				? window.location.reload()
				: this._router.navigateByUrl(
						"/projects/view/" + this.project.id
				);
		});
	}

	orientNorth() {
		this.renderer?.orientNorth();
	}

	addUserMarker() {
		if (this.userCoords.latlng) {
			const { latlng } = this.userCoords;
			Annotations
				.create( {
					name: "User Marker",
					type: AnnotationType.MARKER,
					points: [ latlng ]
				} )
				.then( ( res: any ) => {
					const annotation = Object.assign({}, res, {mapType: this.threeDMode ? '3D' : '2D', data: { points: [latlng] }});
					this.renderer.addAnnotationByType(annotation);
				} );
		}
	}

	unitsUpdated(event) {
		this.selectedUnits = event.value;
		this.renderer.updateMapUnits();
	}
}
