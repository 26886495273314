import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { cloneDeep } from "lodash";

import { ShareLinkComponent, ExportDialog, MarkerImportDialog } from "@app/components";

import { AddLayerDialog } from "@app/pages/viewer/features/add-layer/add-layer.component";
import { AlertService, makePublicShareLink, ModelService, UtilsService } from "@shared/services";

import { MapComponent } from "../map.component";
import { AnnotationType } from "../../viewer/classes/measure";
import { isLabelingAnnotation } from "../utils/map.utils";
import { LabelAnnotation } from "../labeling/classes/labelAnnotation";
import { AnalysisComponent } from "../../ai";
import { Simulate } from "react-dom/test-utils";

export const DATA_FILTERS = {
	ANNOTATIONS: "annotations",
	IMAGES: "images",
	LAYERS: "layers",
	LABELS: 'labels'
};

export const dataIconEnum = {
	"polyline": "timeline",
	"marker": "place",
	"processed": "layers",
	"import": "insert_drive_file",
	"imports": "file_copy",
	"failed": "error_outline",
	"labelSet": "highlight_alt",
	"polyLabelSet": "pentagon",
	"trainingRegion": "grid_on",
}

export const fileIconEnum = {
	"las": "grain",
	"laz": "grain",
	"kml": "public",
	"kmz": "public",
	"dxf": "public",
}

@Component({
	selector: "app-map-sidebar",
	styleUrls: ["./map.sidebar.scss"],
	templateUrl: "./map.sidebar.component.html",
})
export class MapSidebarComponent {
	@Input() map: MapComponent;
	@Input() models: Array<any>;
	@Input() annotations: Array<any>;
	@Input() imageGroups: Array<any>;
	@Input() labelSets: Array<any>;
	@Input() publicView: boolean;
	@Input() mapPermissions;

	public expanded = true;
	public dataFilter: string = DATA_FILTERS.LAYERS;
	public focusedElement = null;
	public showAnnotations = true;
	public showLabelSets = false;
	public labelModeActive = false;
	public showLabelList = false;
	public editItem: {annotation?: any, model?: any, labelSet?: any, type: 'name' | 'details'} | null = null;

	public expandedLabelSet: LabelAnnotation;
	public selectedLabel;

	public createLabelSetOptions = [
		{name: 'Box Label', icon: 'highlight_alt', type: AnnotationType.BOX_LABEL_SET},
		{name: 'Polygon Label', icon: 'pentagon', type: AnnotationType.POLY_LABEL_SET},
		{name: 'Training Data', icon: 'grid_on', type: AnnotationType.TRAINING_REGION}
	]

	public expandedSubPanelLayer;
	public contourOpacity = 60;

	constructor(
		private _modelService: ModelService,
		private _utilsService: UtilsService,
		private _dialog: MatDialog,
		private _alertService: AlertService,
	) {
		this.expanded = !this._utilsService.getIsMobile();
	}

	public toggleSidebar() {
		this.expanded = !this.expanded;
		this.map.renderer._twoDRenderer.triggerDelayedResize();
	}

	public toggleModel(model) {
		model.expanded = !model.expanded;
	}

	public toggleLayer(layer) {
		if (this.map?.renderer?.updateLayerVisibility) {
			layer.visible = !layer.visible;

			this.map.renderer.set2D3DModeToMatchLayer(layer);
			this.map.renderer.updateLayerVisibility(layer, true);

			this.maybeUpdateComparisonLayers(layer);
		}
	}

	public showLayer(layer) {
		if (this.map?.renderer?.focusOnLayer) {
			layer.visible = true;
			this.map.renderer.updateLayerVisibility(layer, true);
			this.map.renderer.focusOnLayer(layer);

			this.maybeUpdateComparisonLayers(layer);
		}
	}

	public showModel(model) {
		this.map.renderer.focusOnModel(model);
	}

	updateModelName(model) {
		const modelToUpdate = {name: model.name, id: model.id};
		this._modelService
			.update(modelToUpdate)
			.then(() => {
				this._alertService.notify(
					"Successfully updated Model name.",
					"success"
				);
				this.clearEditItem();
			})
			.catch((err) => {
				this._alertService.notify(
					"Failed to update Model name",
					"error"
				);
				console.error(err);
			});
	}

	public resetModel(model) {
		model.name = this.editItem.model.name;
		this.clearEditItem();
	}

	public toggleImageGroup(group) {
		group.expanded = !group.expanded;
	}

	public toggleImageGroupVisibility(event, group) {
		group.visible = !group.visible;
		this.map.renderer.updateImageVisibility(group);
		event.stopPropagation();
	}

	public filterLayers(filterType) {
		this.dataFilter = filterType;
		if (filterType !== 'labels') {
			this.map.renderer.selectAnnotation(null);
		} else {
			this.map.renderer.switchView_2D();
		}
	}

	public focusOnElement(element) {
		this.focusedElement = element;
	}

	public openAddLayerDialog() {
		this._dialog.open(AddLayerDialog, {
			data: {
				project: this.map.project,
			},
		});
	}

	public getDataIcon(type, layers = null) {
		if (type === 'import' && layers > 1) {
			return dataIconEnum.imports;
		} else {
			return dataIconEnum[type];
		}
	}

	layerMatchesMode(layer): boolean {
		return this.map.renderer.layerMatchesMode(layer);
	}

	maybeUpdateComparisonLayers(layer) {
		if(!this.map.comparisonModeActivated)
			return;

		if(layer.visible) {
			if (!this.map.comparisonLayers.left) { // if theres no left, set the new one to left
				this.map.comparisonLayers.left = layer;
				this.map.comparisonLayers.previousSide = 1; // 1 is left
			} else if(!this.map.comparisonLayers.right) { // if there no right, set the new one to right
				this.map.comparisonLayers.right = layer;
				this.map.comparisonLayers.previousSide = 2;
			} else { // must already have a left and a right
				let layerToRemove = this.map.comparisonLayers.left;
				if (this.map.comparisonLayers.previousSide !== 1) {
					this.map.comparisonLayers.left = null;
				} else {
					layerToRemove = this.map.comparisonLayers.right;
					this.map.comparisonLayers.right = null;
				}
				layerToRemove.visible = false;
				this.map.renderer.updateLayerVisibility(layerToRemove, true);
				this.maybeUpdateComparisonLayers(layer);
			}
		} else {
			if (this.map.comparisonLayers.left === layer) {
				this.map.comparisonLayers.left = null;
			} else if(this.map.comparisonLayers.right === layer) {
				this.map.comparisonLayers.right = null;
			}
		}
		this.map.renderer._twoDRenderer.compareLayers(this.map.comparisonLayers.left, this.map.comparisonLayers.right);
	}

	public toggleAnnotations(e): void {
		this.showAnnotations = e.checked;
		this.map.renderer.updateAnnotationVisibility(this.showAnnotations);
	}

	public toggleAnnotationExpand(annotation) {
		this.clearEditItem();
		annotation.expanded = !annotation.expanded;

		annotation?.type === 'labelSet' && this.map.labelTools.resetLabelSelection();

		annotation.expanded ?
			this.map.renderer.selectAnnotation(annotation)
			: this.map.renderer.selectAnnotation(null), this.selectedLabel = '';

		if(this.map.toolbar.elevationModeActivated) {
			this.map.exitElevationMode();
		}
	}

	public expandContourSubPanel(layer) {
		this.expandedSubPanelLayer = layer;
		layer.expanded = !layer.expanded;
		this.models.forEach((projectModel) => {
			projectModel.layers.forEach((modelLayer) => {
				if (modelLayer.id !== layer.id) {
					modelLayer.expanded = false;
				}
			})
		})
	}

	public adjustContourInterval(selectedInterval) {
		this.expandedSubPanelLayer.tempSelectedInterval = selectedInterval;

		this.map.renderer._twoDRenderer.updateContour(this.expandedSubPanelLayer);
	}

	public calculateContourMeasurements(layer, type) {
		return layer[type] * this.map.selectedUnits.metersConversion;
	}

	public calculateInterval(option) {
		return option * this.expandedSubPanelLayer.baseIntervalDelta * this.map.selectedUnits.metersConversion;
	}

	public adjustContourLineWeight(selectedWeight) {
		this.expandedSubPanelLayer.tempSelectedWeight = selectedWeight;

		this.map.renderer._twoDRenderer.updateContour(this.expandedSubPanelLayer);
	}

	public toggleContourVisibility(contourLayer, type) {
		if (type === 'index') {
			contourLayer.showIndexContours = !contourLayer.showIndexContours;
		}

		if (type === 'labels') {
			contourLayer.showContourLabels = !contourLayer.showContourLabels;
		}
		this.map.renderer._twoDRenderer.updateContour(contourLayer);
	}

	public selectContourColor(selectedColor) {
		this.expandedSubPanelLayer.color = selectedColor;

		this.map.renderer._twoDRenderer.updateContour(this.expandedSubPanelLayer);
	}

	public selectIndexContourColor(selectedColor) {
		this.expandedSubPanelLayer.indexColor = selectedColor;

		this.map.renderer._twoDRenderer.updateContour(this.expandedSubPanelLayer);
	}

	public highlightAnnotation(annotation) {
		this.unhighlightAllAnnotations();
		if (isLabelingAnnotation(annotation)) {
			this.dataFilter = DATA_FILTERS.LABELS;
			this.map.threeDMode = false;
		} else {
			this.dataFilter = DATA_FILTERS.ANNOTATIONS;
		}
		annotation.expanded = true;

		// if annotation is out of view, scroll the sidebar to see it
		document.getElementById(annotation.id)?.scrollIntoView(
			{ block: 'start', inline: 'nearest' });
	}

	public scrollToBottom() {
		const list = document.getElementById("annotationsList");
		if (list)
			list.scrollTo(0, list.scrollHeight);
	}

	unhighlightAllAnnotations() {
		this.map.annotations.forEach(annotation => {
			annotation.expanded = false;
		})

		this.map.labelSets.forEach(labelSet => {
			labelSet.expanded = false;
			this.map.labelTools.removeResizePoints();
			this.map.labelTools.resetLabelSelection();
		})
	}

	public openExportDialog() {
		this._dialog.open(ExportDialog, {
			data: {
				layerGroups: cloneDeep(this.map.models),
				project: this.map.project,
			},
		});
	}

	openAiInferencingWizard() {
		this._dialog.open(AnalysisComponent, {
			data: {
				project_id: this.map.project.id,
			}
		});
	}

	public openMarkerImportDialog() {
		this._dialog.open(MarkerImportDialog, {
			data: {
				project: this.map.project,
				renderer: this.map.renderer
			},
		});
	}

	createOrModifyShareLink = (modelId, state?): Promise<string> =>
		this._modelService
			.createOrModifyShare(modelId, state)
			.then((rtnShare) => makePublicShareLink(rtnShare.public_guid));

	openShareLinkDialog(): void {

		const dialogData = {
			createLinkFunc: (modelId) => this.createOrModifyShareLink(modelId),
			shareType: "model",
			shareablesList: this.models
		}

		// If non-public, allow toggling
		if (!this.publicView) Object.assign(dialogData, {
			setLinkAbilityFunc: (modelId, state) => this.createOrModifyShareLink(modelId, state) }
		)

		this._dialog.open(ShareLinkComponent, { data: dialogData });
	}

	public updateLayerOpacity(layer, event): void {
		layer.opacity = event.value;
		this.map.renderer.updateLayerOpacity(layer);
	}

	getAnnotationIcon(annotation): string {
		return annotation.type === 'polyline' ? 'assets/icons/link.svg' : 'assets/icons/marker.svg'
	}

	public deleteAnnotation(annotation) {
		this.map.renderer.removeAnnotation(annotation);
	}

	public deleteLabel(labelSet, label) {
		labelSet.removeLabel(label);
	}

	public updateAnnotationName(event, annotation) {
		annotation.name = event.target.value;
		annotation.markAndUpdate();
	}

	public updateAnnotationDetails(event, annotation) {
		annotation.details = event.target.value;
		annotation.markAndUpdate();
	}

	public hideElevationModeLayerIcons(layer) {
		return !this.map.toolbar.elevationModeActivated;
	}

	public showAvailableElevationModeLayers(layer) {
		return this.map.toolbar.elevationModeActivated && (layer.fileType === 'potree');
	}

	public showNonElevationModeLayersIcons(layer) {
		return this.map.toolbar.elevationModeActivated && (layer.fileType !== 'potree');
	}

	public hideComparisonModeMapType(layer) {
		return !this.map.comparisonModeActivated;
	}

	public showAvailableComparisonModeLayers(layer) {
		return this.map.comparisonModeActivated && (layer.fileType === 'geotiffs');
	}

	public showNonComparisonModeLayersIcons(layer) {
		return this.map.comparisonModeActivated && (layer.fileType !== 'geotiffs');
	}

	public focusOnImage(image) {
		this.map.renderer.focusOnImage(image);
	}

	public highlightImage(image) {
		if(image.twoDMarker)
			image.twoDMarker.setRadius(8);

		if(image.threeDMarker)
			image.threeDMarker.scale.set(2, 2, 2);
	}

	public unhighlightImage(image) {
		if(image.twoDMarker)
			image.twoDMarker.setRadius(4)

		if(image.threeDMarker)
		image.threeDMarker.scale.set(1, 1, 1);
	}

	public highlightLabel(label, labelSet) {
		this.expandedLabelSet = labelSet;
		this.expandedLabelSet.highlight(label);
	}

	public unhighlightLabel(labelSet) {
		labelSet?.unhighlight();
	}

	public goToLabelSetLabel(labelSet, label) {
		this.map.labelTools.focusOnLabelSetLabel(labelSet, label);
	}

	public toggleLabelSets(): void {
		this.showLabelSets = !this.showLabelSets;
		for(let labelSet of this.labelSets) {
			labelSet.visible = this.showLabelSets;
			this.map.labelTools.updateLabelsetVisibility(labelSet);
		}

		if(!this.showLabelSets) {
			this.map.renderer.selectAnnotation(null);
		}
	}

	activateDeactivateLabelingMode(annotation) {
		this.labelModeActive = !this.labelModeActive;

		annotation.expanded && this.labelModeActive ?
			this.map.renderer.selectAnnotation(annotation)
			: this.map.renderer.selectAnnotation(null);

		if (this.labelModeActive) {
			this.map.labelTools.resetLabelSelection();
			this.selectedLabel = '';
		}
	}

	public toggleLabelSetVisibility(event, labelSet): void {
		labelSet.visible = !labelSet.visible;
		this.map.labelTools.updateLabelsetVisibility(labelSet);
		event.stopPropagation();

		if (!labelSet.visible) {
			this.map.labelTools.exitLabelMode();
			labelSet.expanded = false;
		}
	}

	public toggleLabelListExpand(focusElement) {
		this.showLabelList = !this.showLabelList;

		if (this.showLabelList) {
			this.focusedElement = focusElement;
		} else {
			this.focusedElement = null;
			this.selectedLabel = '';
		}
	}

	public createNewLabelSet(option) {
		this.map.labelTools.addNewLabelSet(option.type);

		if (this.map.comparisonModeActivated) {
			this.map.toolbar.toggleComparisonMode();
		}
	}

	public deleteLabelSet(labelSet) {
		this.map.labelTools.removeLabelSet(labelSet);
	}

	public updateLabelSetName(event, labelSet) {
		labelSet.name = event.target.value;
		labelSet.save();
	}

	public updateLabelSetDetails(event, labelSet) {
		labelSet.details = event.target.value;
		labelSet.save();
	}

	public updateAnnotationField(event, annotation, fieldKey) {
		annotation.fields[fieldKey] = event.target.value;
		annotation.markAndUpdate();
	}

	trackByFn(index, item) { return index; }

	public setEditItem(annotation, itemType: string, fieldType: "name" | "details") {
		const { id, name, details } = annotation;
		// Extracted b/c a copied object also copies state (setting clone.name = 'test', original.name would now be 'test')
		const annotationClone = {id, name, details};
		this.editItem = { [itemType]: annotationClone, type: fieldType };
	}

	public clearEditItem() {
		this.editItem = null;
	}

	public resetAnnotation(annotation?) {
		if (this.editItem?.annotation) {
			if (!annotation?.id) {
				annotation = this.annotations.find(x => x.id === this.editItem?.annotation?.id)
			}
			const { type } = this.editItem;
			annotation[type] = this.editItem.annotation[type];
			annotation.markAndUpdate();
		}
		this.clearEditItem();
	}

	public resetLabelSet(labelSet) {
		if (this.editItem?.labelSet) {
			labelSet.name = this.editItem?.labelSet.name;
			labelSet.save();
		}
		this.clearEditItem();
	}

	public isEditType(editItem, itemType: string, fieldType: "name" | "details"): boolean {
		return (this.editItem?.[itemType]?.id === editItem.id) && (this.editItem?.type === fieldType);
	}

	public notRendered(stringOrObj: string | { status: string }) {
		const statusString = (typeof stringOrObj === "string") ? stringOrObj : stringOrObj.status;
		if (statusString && typeof statusString === "string") {
			return statusString.toLowerCase() !== "rendered";
		}
		return true;
	}
}
