
/* Imports */
import { Model } from "./model.model";
import { Image } from "./image.model";
import { Share } from "./share.model";
import {Order} from '@shared/models/order.model';

// Project Model
export class Project {

	public id: number;
	public organization_id?: number;
	public name: string;
	public descriptors?: {
		active: number;
		tags: string[];
	};
	public created_by_user_id?: number;
	public created_at?: Date;
	public updated_at?: Date;
	public active?: number;
	public trash?: number = 0;
	public batches?: ImageBatch[];
	public thumbnail?: string;
	public displayThumbnail?: string;
	public models?: Model[];
	public orders?: Order[];
	public shares?: Share[];
	public project_files?: any[];
	public models_count?: number;
	public orders_count?: number;
	public batches_count?: number;
	public project_files_count?: number;
	public exports_count?: number;
	public favorited_id?: number;
	public favorited?: boolean;
	public organization?: {
		id: number;
		name: string;
	};

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor

}	// End-of class ProjectModel

export interface ImageBatch {
	id: number;
	created_by_user_id: number;
	created_at: Date;
	updated_at: Date;
	active: number;
	organization_id: number;
	name: string;
	description: string;
	images: Image[];
	project_id: number;
	dragDropID?: string;
	children?: Image[];
	selected?: boolean;
	sensor?: string;
}

export interface ProjectFile {
	id: number;
	project_if: number;
	name: string;
	size: number;
	tupe: string;
	filename: string;
	created_at: Date;
	updated_at: Date;
	trash: number;
}


// export interface Image {
// 	id: number;
// 	size: number;
// 	guid: string;
// 	extension: string;
// 	content_type: string;
// 	created_by_id: number;
// 	storage_url: string;
// 	small_url: string;
// 	medium_url: string;
// 	large_url: string;
// 	latitude: number;
// 	longitude: number;
// 	altitude: number;
// 	rotation: number;
// 	x_res: number;
// 	y_res: number;
// 	pixels: number;
// 	descriptors: {
// 		make: string;
// 		model: string;
// 		iso: number;
// 		exposure: number;
// 		f_stop: number;
// 		focal_length: number;
// 		equivalent_focal_length: number;
// 		captured_at: string;
// 	};
// 	trash: number;
// 	active: number;
// 	created_at: Date;
// 	updated_at: Date;
// }

// export interface Model {
// 	id: number;
// 	project_id: number;
// 	name: string;
// 	status: string;
// 	renders: any;
// 	descriptors: object;
// 	created_by_user_id: number;
// 	created_at: Date;
// 	updated_at: Date;
// 	active: number;
// 	trash: number;
// 	cesium_files: CesiumFile[];
// 	potree_files: PotreeFile[];
// 	geotiffs: Geotiff[];
// }

// interface CesiumFile {
// 	id: number;
// 	model_id: number;
// 	size: number;
// 	guid: string;
// 	json_file: string;
// 	status: string;
// 	active: number;
// 	created_by_user_id: number;
// 	created_at: Date;
// 	updated_at: Date;
// 	url: string;
// }

// interface PotreeFile {
// 	id: number;
// 	model_id: number;
// 	size: number;
// 	guid: string;
// 	json_file: string;
// 	status: string;
// 	active: number;
// 	created_by_user_id: number;
// 	created_at: Date;
// 	updated_at: Date;
// 	url: string;
// }

// interface Geotiff {
// 	id: number;
// 	type: string;
// 	guid: string;
// 	uploaded_at: Date;
// 	tiled_at: Date;
// 	north: number;
// 	south: number;
// 	east: number;
// 	west: number;
// 	zoom: number;
// 	size: number;
// 	inspection_id?: any;
// 	model_id: number;
// 	active: number;
// 	created_by_id: number;
// 	created_at: Date;
// 	updated_at: Date;
// 	url: string;
// }
