
/* TODO: @remove remove-subscription-required : RegisterCycleComponent
 * or could possibly to refactored into another subscription choosing page when subscriptions get re-added?
*/

/* Imports */
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FinanceService } from "@app/shared/services";
import { ChargebeePlan } from "@shared/models/chargebee-plan.model";
import { byId } from '@shared/services/utils.service';
import {_Subscription} from "@shared/services/subscription.service";

const pickPlanDisplayInfo = ({
								 name = "N/A",
								 id,
								 description = "",
								 period_unit = "year",
								 price = "N/A",
							 }) => {
	const [header, ...details] = description.split("\n- ");
	return {
		name,
		id,
		price: parseInt(price) * .01, // price comes without the proper decimal point e.g. 680400 should be 6,804.00. This corrects the issue.
		isYearly: period_unit === "year",
		description: {
			header,
			details,
		},
	};
};

@Component({
	selector: "app-register-cycle",
	templateUrl: "./register-cycle.component.html",
	styleUrls: ["./register-cycle.component.scss"],
})
export class RegisterCycleComponent implements OnInit {
	@Input("displayPlan") displayPlan: string;
	@Input("freeToPaid") freeToPaid: string;

	@Input("activePlan") set activePlan(plan) {
		if (plan) {
			this._activePlan = plan;
			this.hasActiveTrial = _Subscription.isInTrial(plan);
		}
	};

	@Output("displayPlanChange") displayPlanChange = new EventEmitter();

	public hasActiveTrial = false;
	public _activePlan = null;
	public profPlan: any = {}; // TODO: add proper placeholders
	chargeBeePlans: ChargebeePlan[] = [];
	chargeBeePlansForDisplay = [];
	// @ts-ignore
	selectedPlan = pickPlanDisplayInfo({});

	constructor(private _financeService: FinanceService) {}

	ngOnInit() {
		// This will be used in 1029-add-chargebee-plans-to-register-component-subscription-workflow
		this._financeService
			.getPlans()
			.then((plans) => {
				this.chargeBeePlans = plans;
				// @ts-ignore
				this.chargeBeePlansForDisplay = plans.map(pickPlanDisplayInfo);
				const planIndex = this._activePlan ? this.chargeBeePlansForDisplay.findIndex(byId(this._activePlan.plan_id)) : 0;
				this.selectedPlan = this.chargeBeePlansForDisplay[planIndex];
				this.displayPlanChange.emit(this.selectedPlan);
			})
			.catch(console.error);
	} // End-of ngOnInit

	changeValue() {
		this.displayPlanChange.emit(this.selectedPlan);
	}

}	// End-of class RegisterCycleComponent
