
import { PointLayer } from "../../viewer/point.viewer.component";

import { parseLayersFromGeotiffs } from "./geotiff.utils";
import {LAYER_TYPES_2D} from '../../viewer';
import { AnnotationType } from "../../viewer/classes/measure";

export enum LAYER_FILE_TYPE {
	NONE = "none",
	POTREE = "potree",
	GEOTIFF = "geotiffs",
	CESIUM = "cesium",
	OVERLAY = "overlay",
}

/* TODO - can we move these functions to their own file? */
export const sortByNewestToOldest = (a, b) =>
	a.created_at < b.created_at ? 1 : a.created_at === b.created_at ? 0 : -1;

export const shouldShowLayer = (layerData): boolean => {
	// always show 2D layers
	return (LAYER_TYPES_2D[layerData.type] || layerData.status === "Rendered")
		&& isValidLayer(layerData);
};

export const isValidModel = (model): boolean => {
	return (model.trash === 0 && model.active === 1);
};

const isValidLayer = (layer): boolean => {
	return layer.active === 1;
};

export const parseLayersFromModel = (model) => {
	parseLayersFromPotreeFiles(model);
	parseLayersFromGeotiffs(model);
	parseLayersFromCesiumFiles(model);
	parseLayersFromOverlayFiles(model);
};

const parseLayersFromPotreeFiles = (model) => {
	// Get Potree files, sort so newest is last, and will be selected for models
	const potree_files = model.potree_files.sort(sortByNewestToOldest);
	potree_files.forEach((layerData) => {
		if (shouldShowLayer(layerData)) {
			const newLayer = {
				createdBy: "",
				createdOn: layerData.created_at,
				errors: {},
				fileType: LAYER_FILE_TYPE.POTREE,
				id: "p" + layerData.id,
				mapType: "3D",
				model_id: model.id,
				name: getGoodLayerName(layerData.name ?? layerData.type),
				showDetails: false,
				source: layerData.source,
				status: layerData.status,
				type: layerData.type + " point cloud",
				url: layerData.url,
				visible: false,
			} as PointLayer;
			model.layers.push(newLayer);
		}
	});
	return model;
};

const parseLayersFromCesiumFiles = (model) => {
	const cesium_files = model.cesium_files.sort(sortByNewestToOldest);
	cesium_files.forEach((layerData) => {
		if (shouldShowLayer(layerData)) {
			const newLayer = {
				createdBy: layerData.created_by_user_id,
				createdOn: layerData.created_at,
				errors: {},
				fileType: LAYER_FILE_TYPE.CESIUM,
				id: "c" + layerData.id,
				mapType: "3D",
				model_id: model.id,
				name: getGoodLayerName(layerData.name ?? layerData.type ?? "3D Mesh"),
				showDetails: false,
				source: layerData.source,
				status: layerData.status,
				// TODO - set layer name from data - UX-204
				type: "3D model",
				url: layerData.url,
				visible: false,
			} as PointLayer;
			model.layers.push(newLayer);
		}
	});
	return model;
};

const parseLayersFromOverlayFiles = (model) => {
	const overlays = model.overlays?.sort(sortByNewestToOldest);
	overlays?.forEach((layerData) => {
		if (shouldShowLayer(layerData)) {
			const newLayer = {
				createdBy: layerData.created_by_user_id,
				createdOn: layerData.created_at,
				errors: {},
				fileType: LAYER_FILE_TYPE.OVERLAY,
				id: layerData.id,
				mapType: "2D 3D",
				model_id: model.id,
				name: getGoodLayerName(layerData.name),
				showDetails: false,
				source: layerData.viewer_file,
				status: layerData.status,
				type: "overlay",
				overlayType: layerData.type,
				url: `/v2/overlays/${layerData.id}?type=viewer`,
				visible: false,
			} as PointLayer;
			model.layers.push(newLayer);
		}
	});
	return model;
};

// replace the default names with some better ones
export const getGoodLayerName = (originalName) => {
	switch(originalName?.toLowerCase()) {
		case "dense":
		case "dense_points.laz":
		case "pointcloud-dense":	return "Dense Point Cloud";

		case "sparse":
		case "sparse_points.laz":
		case "pointcloud-sparse":	return "Sparse Point Cloud";

		case "orthomosaic": return "Orthomosaic";
		case "dem": return "DSM";
	}
	return originalName ?? "Layer";
}

export const isValidAnnotation = (annotation) => {
	const hasData = !!annotation.data;
	let hasPoint = false;
	if(annotation.type === AnnotationType.MARKER) hasPoint = annotation.data?.points?.length == 1;
	if(annotation.type === AnnotationType.POLYLINE) hasPoint = annotation.data?.points?.length > 0;

	if(annotation.type === AnnotationType.CUSTOM_MARKER) return true;
	if(isLabelingAnnotation(annotation) && annotation.descriptors) return true;

	return hasData && hasPoint;
}

export const getAnnotationMapType = (annotation) => {
	if(annotation.data?.points?.length > 0) {
		if(annotation.data.points[0].x
			&& annotation.data.points[0].y
			&& annotation.data.points[0].z)
			return "3D";
		else if(annotation.data.points[0].lat
			&& annotation.data.points[0].lng)
			return "2D";
	}
	else if(isLabelingAnnotation(annotation)) return "2D";
	else if(annotation.type === AnnotationType.CUSTOM_MARKER) return "2D";
	return "unknown";
}

export const isLabelingAnnotation = (annotation) => {
	if(annotation && annotation.type &&
		(annotation.type === AnnotationType.BOX_LABEL_SET
			|| annotation.type === AnnotationType.POLY_LABEL_SET
			|| annotation.type === AnnotationType.TRAINING_REGION)
	)  return true;

	return false;
}

export const calculateImageGroupCenter = (group) => {
	const totalCenterPoint = { lat: 0, lng: 0};
	let imagesCounted = 0;
	group?.images?.forEach( image => {
		const imageCenter = getImageCenter(image);
		if(imageCenter) {
			totalCenterPoint.lat += imageCenter.lat;
			totalCenterPoint.lng += imageCenter.lng;
			imagesCounted++;
		}
	})

	if(imagesCounted > 0)
		return {
			lat: totalCenterPoint.lat / imagesCounted,
			lng: totalCenterPoint.lng / imagesCounted
		};
	return { lat: 0, lng: 0};
}

const getImageCenter = (image) => {
	if(image.latitude && image.longitude) {
		return { lat: image.latitude, lng: image.longitude };
	}
	return null;
}

export const randomColor = () => {
	return Math.floor(Math.random()*16777215).toString(16);
}
