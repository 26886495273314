import moment from "moment";
import {_Organization as Organization} from "@shared/models/organization.model";
import {Subscription as SubscriptionDef } from "@shared/models/subscription.model";

// The original Aerial Plans.
// These will be phased out and replaced by Subscription in there entirely soon enough,
// but in order to keep the app working for the time being, we still need them
const aerialPlanTemplates = {
	pro: {
		id: 1,
		name: "Professional",
		description: "",
		price_month: 199.95,
		price_year: 2039.49,
		users_included: 1000000,
		additional_user_cost_month: 0,
		additional_user_cost_year: 0,

		gp_included_month: 12,
		gp_included_year: 144,
		additional_gp_cost: 8,
		storage_included_per_user: 500,
		additional_storage_cost_month: 0.0236,
		additional_storage_cost_year: 0.2402,
		publicly_available: 1,
		active: 1,
		created_at: "2019-10-08T00:01:16.000Z",
		updated_at: "2019-10-08T00:01:16.000Z",
	},
	free: {
		id: 0,
		name: "Free",
		description: "Free Trial Plan",
		price_month: 0,
		price_year: 0,
		users_included: 1,
		additional_user_cost_month: 0,
		additional_user_cost_year: 0,
		gp_included_month: 0,
		gp_included_year: 0,
		additional_gp_cost: 100,
		storage_included_per_user: 12,
		additional_storage_cost_month: 1,
		additional_storage_cost_year: 1,
		publicly_available: 1,
		active: 1,
		created_at: "2020-09-28T04:00:00.000Z",
		updated_at: "2020-09-28T04:00:00.000Z",
	},
};

export class _Subscription {
    /**
     * The function will convert JS time or time in milliseconds to seconds.
     */
    static toJStime(time: Date | number): number {
        return moment(time).unix();
    }

    /**
     * The function will convert seconds to an ISO date string like we store on the backend for plans.
     * Chargebee uses Unix timestamps for dates, so they appear in seconds.
     */
    static toJSDate(time: number | string): string {
    	if (!time) {
    		return null;
		} else if (typeof time === "string") {
    		return time;
		} else {
			return new Date(time * 1000)?.toISOString()
		}
    }

    /**
     * This allows us to get properties of a subscription without having to dig into the Organization first
     */
    static getSub(subOrObj: SubscriptionDef | Organization): SubscriptionDef {
        return "subscription" in subOrObj ? subOrObj.subscription : subOrObj;
    }

    /**
     * By default this returns the time as a moment object to simplify it's usage
     * You may pass `false` as the second parameter to get the date in seconds
     */
    static getTrialStartDate(sub: Organization | SubscriptionDef, asMoment: boolean = true): number | moment.Moment {
        return asMoment
            ? moment.unix(_Subscription.getSub(sub).trial_start)
            : _Subscription.getSub(sub).trial_start;
    }

    /**
     * By default this returns the time as a moment object to simplify it's usage
     * You may pass `false` as the second parameter to get the date in seconds
     */
    static getTrialEndDate(sub: Organization | SubscriptionDef, asMoment: boolean = true): number | moment.Moment {
        return asMoment
            ? moment.unix(_Subscription.getSub(sub).trial_end)
            : _Subscription.getSub(sub).trial_end;
    }

    /**
     * By default this returns the time as a moment object to simplify it's usage
     * You may pass `false` as the second parameter to get the date in seconds
     */
    static getNextBillingDate(sub: Organization | SubscriptionDef, asMoment: boolean = true): number | moment.Moment {
        return asMoment
            ? moment.unix(_Subscription.getSub(sub).next_billing_at)
            : _Subscription.getSub(sub).next_billing_at;
    }

    static getEndOfSubscriptionDate(sub: Organization | SubscriptionDef, asMoment: boolean = true): number | moment.Moment {
        return _Subscription.isInTrial(sub) ?
            _Subscription.getTrialEndDate(sub, asMoment) :
            asMoment ?
                _Subscription.getSub(sub).current_term_end ? moment.unix(_Subscription.getSub(sub).current_term_end) : moment.unix(_Subscription.getSub(sub).next_billing_at)
                : _Subscription.getSub(sub).current_term_end ?? _Subscription.getSub(sub).next_billing_at;
    }

    static getStatus(subOrObj) {
        return _Subscription.getSub(subOrObj).status;
    }

    /**
     * A subscription is "in trial" as long as they have not passed their `trial_end` date.
     * This means they can have any status other than canceled and still be in trial.
     */
    static isInTrial(sub: Organization | SubscriptionDef, currentDate: number = Date.now()): boolean {
        return (
            _Subscription.getStatus(sub) === "in_trial" ||
            (_Subscription.isActive(sub) &&
                currentDate / 1000 < _Subscription.getSub(sub).trial_end)
        );
    }

    /**
     * I assume that if the subscription has been canceled there is no intention to renew, or at least not through the usual means
     */
    static isNonRenewing(sub: Organization | SubscriptionDef): boolean {
        return _Subscription.getStatus(sub) === "non_renewing" || _Subscription.isCancelled(sub);
    }

    static isCancelled(sub: Organization | SubscriptionDef): boolean {
        return (_Subscription.getStatus(sub) === "cancelled" || (!('next_billing_at' in _Subscription.getSub(sub))));
    }

    static isPaused(sub: Organization | SubscriptionDef): boolean {
        return _Subscription.getStatus(sub) === "paused";
    }

    static isActive(sub) {
        return !(_Subscription.isPaused(sub) || _Subscription.isCancelled(sub));
    }

    static hasExpired(sub) {
		const endDate = _Subscription.getSub(sub).trial_end
			? _Subscription.getTrialEndDate(sub) as moment.Moment
			: _Subscription.getEndOfSubscriptionDate(sub) as moment.Moment
            return _Subscription.isNonRenewing(sub) && endDate.isBefore(Date.now())
	}

    static setSubscription(subOrObj: SubscriptionDef | Organization) {
        let sub = _Subscription.getSub(subOrObj);
		if(sub){
		    sub.created_at = Subscription.toJSDate(sub.created_at);
		    sub.expires_at = Subscription.toJSDate(
		    	Subscription.isInTrial(sub)
		    		? sub.trial_end
		    		: sub.current_term_end
		    );
		    sub.price = parseInt(sub.plan_amount) * .01; // price comes without the proper decimal point e.g. 680400 should be 6,804.00. This corrects the issue.
		    sub.renew = !Subscription.isNonRenewing(sub);
		}
		return sub;
    }

    /**
     * Aerial's Plans are being deprecated and slowly removed from the application.
     * Until they can be completely and safely removed we will act as if we are using plans.
     */
    static toAerialPlan({subscription: sub, ...org}) {
    	if(sub === null) {
			throw `Org ${org.id} doesn't have a subscription`
		}

        const orgPlan = {
            id: org.id,
            active: +!sub.deleted,
            organization_id: org.id,
            created_by_id: org.user_id,
            duration: sub.billing_period_unit + "ly",
            additional_gp: 0,
            additional_storage: 0,
            additional_users: 0,
            created_at: _Subscription.toJSDate(sub.created_at),
            expires_at: _Subscription.toJSDate(
                _Subscription.isInTrial(sub)
                    ? sub.trial_end
                    : sub.current_term_end
            ),
            renew: _Subscription.isNonRenewing(sub) ? 0 : 1,
            plan_id: _Subscription.isInTrial(sub) ? 0 : 1,
            plan: null,
            reserved_users: 0,
            start_at: _Subscription.toJSDate(sub.started_at),
            updated_at: _Subscription.toJSDate(sub.updated_at),
        };

        orgPlan.plan = _Subscription.isInTrial(sub)
            ? aerialPlanTemplates.free
            : aerialPlanTemplates.pro;

        return orgPlan;
    }
}

export const Subscription = _Subscription
