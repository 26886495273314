
/* Imports */
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Project } from '../../../shared/models';

/* Services */
import { AuthenticationService, FavoriteService, ProjectService, Favorite } from '../../../shared/services';
import { PermissionService } from '../../../shared/services/permission.service';

@Component({
	selector: 'aerial-list-item-dialog-options',
	templateUrl: './list-item-dialog-options.component.html',
	styleUrls: ['./list-item-dialog-options.component.scss']
})
export class AerialListItemDialogOptions implements OnInit {

	@ViewChild(MatMenuTrigger) itemMenuTrigger: MatMenuTrigger;

	/* Inputs */
	public _project: Project;
	@Input('project') set project(proj: Project) {
		this._project = proj;
		this.favorited_id = proj['favorited_id'];
		this.favorited = (this.favorited_id != undefined || this.favorited_id != null);

		const user = this._authService.user;

		this.canShare = !proj['shared_with_user']
			|| proj["shares"].find(x => x.user.id === user.id)?.permissions === "admin";
	}

	get project(): Project { return this._project; }

	@Input('userPermissions') userPermissions;
	public hasPermissionToShare: boolean = false;

	/* Outputs */
	@Output('eventTrigger') eventTrigger: EventEmitter<any> = new EventEmitter();
	public favorited_id: number;
	public favorited: boolean;
	public canShare: boolean = false;

	constructor(
		private _favoriteService: FavoriteService,
		private _projectService: ProjectService,
		private _authService: AuthenticationService,
		private _permissionService: PermissionService,
	) {

	}	// End-of constructor

	ngOnInit() {
		this.hasPermissionToShare = this.userPermissions?.reader && !(this.userPermissions.admin || this.userPermissions.process || this.userPermissions.user);

	}	// End-of OnInit

	copyLink() {

		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = "www.aerialapplications.com";
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);

		alert("Copied the link: " + selBox.value);

	}	// End-of copyLink

	isProcessRank() {
		return this.userPermissions?.["process"] ?? false;
	}

	closeEvent(text): void {
		this.eventTrigger.emit({ project: this._project, text: text });
	}	// End-of renameProject
}	// End-of class ApplistItem
