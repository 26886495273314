

<ng-container *ngIf="!isProcessing; else t_skeleton">
	<mat-table *ngIf="dataSource?.data?.length > 0"
		[dataSource]="dataSource"
		matSort
		matSortDisableClear>

		<ng-container matColumnDef="select">
			<mat-header-cell *matHeaderCellDef class="cell-0-5">
				<mat-checkbox (change)="$event ? selectAll() : null"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()"
					[aria-label]="selectRow()"
	                 data-testid="select-all"
				>
				</mat-checkbox>
			</mat-header-cell>
			<mat-cell *matCellDef="let row" class="cell-0-5">
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="$event ? selection.toggle(row) : null"
					[checked]="selection.isSelected(row)"
					[aria-label]="selectRow(row)">
				</mat-checkbox>
			</mat-cell>
		</ng-container>


		<!-- Process Type Column -->
		<ng-container matColumnDef="process_type">
			<mat-header-cell class="cell-1" mat-sort-header *matHeaderCellDef disableClear> Process Type </mat-header-cell>
			<mat-cell class="cell-1 capitalized" *matCellDef="let row">
				<span class="ellipsis">{{ row.process }}</span>
			</mat-cell>
		</ng-container>



		<!-- Type Column -->
		<ng-container matColumnDef="type">
			<mat-header-cell class="cell-1" mat-sort-header *matHeaderCellDef disableClear> Type </mat-header-cell>
			<mat-cell class="cell-1" *matCellDef="let row">
				{{getDisplayValue(row.type, 'text')}}
			</mat-cell>
		</ng-container>



		<!-- Project Name Column -->
		<ng-container matColumnDef="project_name">
			<mat-header-cell class="cell-2" mat-sort-header *matHeaderCellDef disableClear> Project Name </mat-header-cell>
			<mat-cell class="cell-2" *matCellDef="let row">
				{{ row.project_name }}
			</mat-cell>
		</ng-container>


		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<mat-header-cell mat-sort-header *matHeaderCellDef disableClear class="cell-2"> Name </mat-header-cell>
			<mat-cell *matCellDef="let row" class="cell-2">
				<span *ngIf="row.small_url" class="image-container">
					<img [src]="rootURL + row.small_url" (error)="handleImageLoadError($event)">
				</span>
				<span *ngIf="!row.small_url && row.type" class="text-container">
					<mat-icon>{{getDisplayValue(row.type, 'icon')}}</mat-icon>
				</span>
				<span data-testid="name-cell" class="title-text" [matTooltip]="pageIsTrash ? 'Toggle' : row.name">{{row.name}}</span>
				<mat-icon matTooltip="Favorited" *ngIf="row['favorited'] && !pageIsFavorites" style="margin: auto 8px; cursor: pointer; color: #6a7483;">star_border</mat-icon>
			</mat-cell>
		</ng-container>


		<!-- Size Column -->
		<ng-container matColumnDef="size">
			<mat-header-cell mat-sort-header *matHeaderCellDef disableClear class="cell-1">
				Size
			</mat-header-cell>
			<mat-cell class="cell-1" *matCellDef="let row">
				{{row.size ? (row.size | bytes) : '---'}}
			</mat-cell>
		</ng-container>


		<!-- Created At Column -->
		<ng-container matColumnDef="created_at">
			<mat-header-cell mat-sort-header *matHeaderCellDef class="cell-1 tablet-hidden">
				Date Created
			</mat-header-cell>
			<mat-cell data-testid="date-modified-cell" *matCellDef="let row" class="cell-1 tablet-hidden">
				{{row.created_at | date}}
			</mat-cell>
		</ng-container>


		<!-- Updated At Column -->
		<ng-container matColumnDef="updated_at">
			<mat-header-cell mat-sort-header *matHeaderCellDef class="cell-1 tablet-hidden">
					Date Modified
				</mat-header-cell>
			<mat-cell data-testid="date-modified-cell" *matCellDef="let row" class="cell-1 tablet-hidden">
				{{row.updated_at | date}}
			</mat-cell>
		</ng-container>


		<!-- Status Column -->
		<ng-container matColumnDef="status">
			<mat-header-cell mat-sort-header *matHeaderCellDef class="cell-2 tablet-hidden">
				Status
			</mat-header-cell>
			<mat-cell data-testid="date-modified-cell" *matCellDef="let row" class="cell-2 capitalized tablet-hidden">
				{{row.status}}
			</mat-cell>
		</ng-container>


		<!-- Elapsed Time Column -->
		<ng-container matColumnDef="elapsed_time">
			<mat-header-cell mat-sort-header *matHeaderCellDef class="cell-2 tablet-hidden">
				Elapsed Time
			</mat-header-cell>
			<mat-cell data-testid="date-modified-cell" *matCellDef="let row" class="cell-2 tablet-hidden">
				{{row.elapsed_time}}
			</mat-cell>
		</ng-container>


		<!-- Time Remaining Column -->
		<ng-container matColumnDef="remaining_time">
			<mat-header-cell *matHeaderCellDef class="cell-1-5 tablet-hidden">
				<span class="min-width">Time Remaining</span>
				<info-box matTooltip="This is a live estimate and will adjust as the model is processed."></info-box>
			</mat-header-cell>
			<mat-cell data-testid="date-modified-cell" *matCellDef="let row" class="cell-1-5 tablet-hidden">
				{{row.remaining_time ? row.remaining_time : "Unknown"}}
			</mat-cell>
		</ng-container>


		<!-- Open Column -->
		<ng-container matColumnDef="open">
			<mat-header-cell mat-sort-header *matHeaderCellDef class="cell-set-width tablet-hidden">
			</mat-header-cell>
			<mat-cell data-testid="date-modified-cell" *matCellDef="let row" class="cell-set-width tablet-hidden">
				<button mat-flat-button color="primary" class="open-button" matTooltip="Open Map">
					<mat-icon class="open-map-icon">map</mat-icon>
				</button>
			</mat-cell>
		</ng-container>


		<!-- Options Column -->
		<ng-container matColumnDef="options">
			<mat-header-cell *matHeaderCellDef class="cell-0-5"> </mat-header-cell>
			<mat-cell *matCellDef="let row; let i = index;" class="cell-0-5" data-testid="action-menu">

				<button mat-icon-button  data-testid="more-button" tooltip="More" (click)="openMenu($event, i, row); $event.stopPropagation();">
					<mat-icon class="list-item-icon">more_horiz</mat-icon>
				</button>

				<button
					class="context-button"
					[matMenuTriggerFor]="itemMenu"
					[style.left]="contextMenuPosition.x"
					[style.top]="contextMenuPosition.y">
				</button>

				<mat-menu
					data-testid="options-menu"
					#itemMenu="matMenu"
					(close)="activeItem = null;"
					(click)="$event.stopPropagation()">
					<ng-template matMenuContent>
						<mat-list>
							<ng-container *ngIf="!pageIsTrash" >
								<mat-list-item (click)="handleAction(menuData, 'open');">
									<mat-icon> zoom_out_map </mat-icon>
									<span> Open </span>
								</mat-list-item>

								<span style="width: 100%; padding: 8px 0;">
									<mat-divider color="#dee0e3"></mat-divider>
								</span>

								<mat-list-item *ngIf="!checkFavorited(menuData);" (click)="handleAction(menuData, 'favorite');">
									<mat-icon> star_border </mat-icon>
									<span> Add to Favorites </span>
								</mat-list-item>

								<mat-list-item *ngIf="checkFavorited(menuData)" (click)="handleAction(menuData, 'favorite');">
									<mat-icon> grade </mat-icon>
									<span> Remove from Favorites </span>
								</mat-list-item>
							</ng-container>

							<ng-container *ngIf="pageIsTrash">
								<mat-list-item (click)="handleAction(menuData, 'recover');" [disabled]="!hasPermissions()">
									<mat-icon> reply </mat-icon>
									<span>
										Recover
									</span>
								</mat-list-item>
								<span style="width: 100%; padding: 8px 0;">
									<mat-divider color="#dee0e3"></mat-divider>
								</span>
								<mat-list-item (click)="handleAction(menuData, 'delete');" [disabled]="!hasPermissions()">
									<mat-icon> delete </mat-icon>
									<span>
										Delete permanently
									</span>
								</mat-list-item>
							</ng-container>

						</mat-list>
					</ng-template>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
		<mat-row
			*matRowDef="let row; let i = index; columns: displayedColumns"
			[ngClass]="{ 'active-item': activeItem === i }"
			(click)="handleAction(row, 'open')"
			(contextmenu)="openMenu($event, i, row)"
		></mat-row>
	</mat-table>
	<div style="width: 100%; display: flex;">
		<span class="H6Black500Regular" style="margin: 40px auto;"
			*ngIf="emptyText && dataSource.data.length <= 0">{{emptyText}}</span>
	</div>
</ng-container>


<ng-template #t_skeleton>
	<div class="skel-container">
		<div class="skel-header">
			<div class="skel-list-title">
				<div class="skel-text-tag skel-shimmer"></div>
			</div>
			<div class="skel-list-options">
				<div class="skel-text-tag skel-shimmer"></div>
			</div>
		</div>
		<div class="skel-common-item" *ngFor="let item of [].constructor(3);">
			<div class="skel-list-image-container skel-shimmer"></div>

			<div class="skel-list-text-container">
				<div class="skel-list-top">
					<div class="skel-title skel-shimmer"></div>
				</div>

				<div class="skel-text-date skel-shimmer"></div>
			</div>

			<div class="skel-list-options">
				<div class="skel-text-tag skel-shimmer"></div>
			</div>
		</div>
	</div>
</ng-template>
