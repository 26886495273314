/* Imports */
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

/* Services */
import { ProjectService } from "./project.service";
import { AuthenticationService } from "./authentication.service";
import { OrganizationService } from "./organization.service";
import { Organization, Project } from "../models";
import { byId } from "@shared/services/utils.service";
import { availableFeatureFlags, flagLayer } from '@shared/featureFlags';
import { ShareService } from '@shared/services/share.service';
import { isNil } from 'lodash';

const basePermissions = {
	"reader": false, // Viewer
	"process": false, // View, Edit, and Process -> Creator ("power user")
	"admin": false, // Admin
};

enum permissionEnum {
	"reader" = 0,
	"process" = 2,
	"admin" = 3,
}

@Injectable()
export class PermissionService {
	public permissionList: Array<any> = [
		{ text: "Admin", value: "admin", rank: 3 },
		{ text: "Creator", value: "process", rank: 2 },
		{ text: "Viewer", value: "reader", rank: 0 },
	];
	public isDeveloper: boolean = false;
	public permissionOverride: any = null;
	public currentMinPermission: "admin" | "process"  | "reader" =
		"admin";
	public currentProject: any = null;
	public userPermissions = basePermissions;

	private userHasPermission: BehaviorSubject<boolean> = new BehaviorSubject(
		false
	);

	constructor(
		private _authService: AuthenticationService,
		private _orgService: OrganizationService,
		private _projectService: ProjectService,
		private _shareService: ShareService
	) {
		this.isDeveloper = this._authService.isDeveloper;
		this._orgService.getActiveOrg().subscribe(({ organization }) => {
			this.getUserPermissions(organization).then(rtnPermissions => {
				this.userPermissions = rtnPermissions;
			});
		})
	}

	setHasPermission(hasPermission: boolean): void {
		this.userHasPermission.next(hasPermission);
	}

	getHasPermission(): Observable<boolean> {
		return this.userHasPermission.asObservable();
	}

	setMinPermission(
		project: number | Project,
		minPermission: "admin" | "process" | "reader"
	): void {
		this.currentMinPermission = minPermission;
		if (project) {
			this.currentProject = project;
			this.checkHasPermission(project, minPermission).then((rtn) => {
				this.setHasPermission(rtn);
			});
		}
	}

	updatePermissionStatus(): void {
		if (this.currentProject) {
			this.checkHasPermission(
				this.currentProject,
				this.currentMinPermission
			).then((rtn) => {
				this.setHasPermission(rtn);
			});
		}
	}

	checkHasPermission(
		project: number | Project,
		minPermission: "admin" | "process"  | "reader"
	): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			if (typeof project === "number") {
				if (flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)) {
					this._projectService.getByIdV2(project).then(rtnProject => {
						this._shareService.getByProjectIdV2(project).then(rtnShares => {
							resolve(
								this.permissionOrgOrShare(Object.assign({}, rtnProject, {
									shares: rtnShares
								}), minPermission)
							);
						})
					})
				} else {
					this._projectService.getById(project).then((rtnProject) => {
						resolve(
							this.permissionOrgOrShare(rtnProject, minPermission)
						);
					});
				}
			} else if (typeof project === "object" && project.organization_id) {
				resolve(this.permissionOrgOrShare(project, minPermission));
			} else {
				reject(null);
			}
		});
	}

	permissionOrgOrShare(
		project: Project,
		minPermission: "admin" | "process"  | "reader"
	): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			this.compareToOrgPermissionsPromise(
				project.organization_id,
				minPermission
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.error({ err });
					// Will error if the user is not a member of the org. Instead, this would have to be shared.
					resolve(
						this.compareToSharePermissions(
							project["shares"],
							minPermission
						)
					);
				});
		});
	}

	compareToOrgPermissionsPromise(
		orgOrOrgId: Organization | number,
		minPermission: "admin" | "process"  | "reader",
		userId?: number
	): Promise<any> {
		const orgId =
			typeof orgOrOrgId === "object" ? orgOrOrgId.id : orgOrOrgId;
		userId = userId || this._authService.user.id;
		return this._orgService.getUserList(orgId).then((rtnList) => {
			let userRole = rtnList.find(byId(userId)).role;
			if (this.permissionOverride && this.isDeveloper) {
				userRole = this.permissionOverride;
			}
			return (
				userRole &&
				this.getRank(userRole) >= this.getRank(minPermission)
			);
		});
	}

	async getUserPermissions(
		orgOrOrgId: Organization | number,
		userId?: number
	): Promise<any> {

		const orgId =
			typeof orgOrOrgId === "object" ? orgOrOrgId?.id : orgOrOrgId;
		userId = userId || this._authService.user?.id;

		if (isNil(orgId) || isNil(userId)) {
			return basePermissions;
		}

		return this._orgService.getUserList(orgId).then((rtnList) => {
			let userRole = rtnList.find(byId(userId)).role;
			if (this.permissionOverride && this.isDeveloper) {
				userRole = this.permissionOverride;
			}
			return this.permissionList.reduce((acc, { value, rank }) => {
				acc[value] = permissionEnum[userRole] >= rank;
				return acc;
			}, {});
		});
	}

	compareToOrgPermissions(
		org: Organization,
		minPermission: "admin" | "process"  | "reader",
		userId: number
	): boolean {
		if (!org?.users?.length) console.error("No users in org");
		const userRole = org.users?.find(byId(userId))?.role ?? "reader";
		return (
			userRole && this.getRank(userRole) >= this.getRank(minPermission)
		);
	}

	async compareToSharePermissions(
		shareList,
		minPermission: "admin" | "process"  | "reader"
	): Promise<any> {
		const userId = this._authService.user.id;
		if (shareList?.length) {
			const sharePermission = shareList.find(
				(x) => x.shared_with_user_id === userId
			).permissions;
			return this.getRank(sharePermission) >= this.getRank(minPermission);
		} else {
			return false;
		}
	}

	getRank(value: string): number {
		return (
			this.permissionList.find((perm) => perm.value === value)?.rank ?? 0
		);
	}

	getPermissionList(): Array<any> {
		return this.permissionList;
	}

	setPermissionOverride(permission): void {
		if (this.isDeveloper) {
			this.permissionOverride = permission;
		} else {
			this.permissionOverride = null;
		}
		this.updatePermissionStatus();
	}
}
