<div data-testid="table-view-container" class="table">
	<div class="table-tools">

		<span class="H4Black700Semibold margin-right-auto">Projects</span>

		<span class="list-count phone-hidden" [ngClass]="{'skel-list-count': isLoading}">
			{{projectList ? projectList.length : '0'}} Projects Total
		</span>

		<span class="phone-hidden" *ngIf="!isLoading">
			<button mat-icon-button style="margin: auto 8px;" (click)="toggleGrid()"
				[ngClass]="gridDisplay ? 'selected': 'unselected'">
				<mat-icon>view_comfy</mat-icon>
			</button>

			<button mat-icon-button (click)="toggleGrid()" [ngClass]="!gridDisplay ? 'selected': 'unselected'">
				<mat-icon>list</mat-icon>
			</button>
		</span>

		<div *ngIf="isLoading" class="skel-list-sort"></div>

		<div *ngIf="!isLoading" class="project-list-sort">

			<button mat-flat-button color="primary" class="mini-button" *ngIf="isMobile" (click)="openCreateProject()"
				[disabled]="!canCreateProject()">
				<mat-icon>add</mat-icon>
				Create
			</button>
			<!-- TODO: Removing until a decision on how to handle filtering is made -->
			<!-- <button mat-button class="dropdown-button" [ngClass]="{'expanded': filterDropdownExpanded}" (click)="filterDropdownExpanded = !filterDropdownExpanded">Filter by <mat-icon>keyboard_arrow_down</mat-icon></button> -->
			<span class="dropdown-text sort-icon" *ngIf="!isMobile && !isLoading">
				<mat-icon>swap_vert</mat-icon>
			</span>
			<mat-select secondary class="H7Black700Regular sort-select" [(value)]="sortSelection"
				(valueChange)="userSortProjectList(projectList)">
				<mat-option class="sort-option" *ngFor="let option of sortOptions" [value]="option">
					<span class="H7Black700Regular"> {{option.text}} </span>
				</mat-option>
			</mat-select>
		</div>
	</div>

	<mat-divider color="#dee0e3"></mat-divider>

	<div class="project-list-content">
		<div class="table-content" *ngIf="!projectList?.length && !isLoading" class="H6Black500Regular"
			 style="text-align: center; margin: 48px;">
			<div>You have not created any Projects.</div>
			<div>Click on ‘Create Project’ to <span class="link" (click)="openCreateProject()">create your first project</span>.</div>
		</div>
		<ng-container *ngTemplateOutlet="t_list"></ng-container>
	</div>
</div>

<ng-template #t_list>
	<mat-list [ngClass]="{'grid-view': gridDisplay}">
		<ng-container *ngIf="!isLoading">
			<ng-container *ngFor="let project of projectList; let ind = index;">
				<ng-container
					*ngTemplateOutlet="gridDisplay ? gridTemplate : listTemplate; context: { $implicit: project, state: {index: ind} }"></ng-container>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="isLoading">
			<ng-container *ngFor="let project of [0,0,0,0,0];">
				<mat-list-image-item [project]="project">
				</mat-list-image-item>
			</ng-container>
		</ng-container>
	</mat-list>
</ng-template>


<ng-template #gridTemplate let-child>
	<mat-list-grid-item [showCreatedAt]="sortSelection.value === 'created_at'" [project]="child"
		(clicked)="navigateTo(child, $event);" (eventTrigger)="handleMenuClose($event, false)">
		<aerial-list-item-dialog-options style="width: 100%;" [project]="child" [userPermissions]="userPermissions"
			(eventTrigger)="handleMenuClose($event)">
		</aerial-list-item-dialog-options>
	</mat-list-grid-item>
</ng-template>

<ng-template #listTemplate let-child>
	<mat-list-image-item [showCreatedAt]="sortSelection.value === 'created_at'" [project]="child"
		(clicked)="navigateTo(child, $event);" (eventTrigger)="handleMenuClose($event, false)">
		<aerial-list-item-dialog-options style="width: 100%;" [project]="child" [userPermissions]="userPermissions"
			(eventTrigger)="handleMenuClose($event)">
		</aerial-list-item-dialog-options>
	</mat-list-image-item>
</ng-template>
