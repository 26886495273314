import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Alert, Project, Organization } from "../../../../shared/models";
import { MatMenuTrigger } from "@angular/material/menu";
import {
	AlertService,
	extractFileExtension,
	makePublicShareLink,
	ModelService,
	ProjectService,
	AuthenticationService,
	PermissionService,
	OrganizationService,
} from '../../../../shared/services';
import { ExportDialog, ShareLinkComponent } from "../../../../components";
import { first } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { availableFeatureFlags, flagLayer } from "../../../../shared/featureFlags";
import { ConfirmationModal } from "@app/components";
import { RenameModal } from "../../../rename";
import { analyticsLayer } from "@shared/analyticsLayer";
import { cloneDeep, isEmpty } from 'lodash';
import { loadLayersFromModel } from "../../../viewer";
import { dataIconEnum, fileIconEnum } from "@app/pages/map/sidebar/map.sidebar.component";
import { MatTableDataSource } from '@angular/material/table';

const dataLabelObject = {
	"Sparse Point Cloud": "grain",
	"Dense Point Cloud": "blur_on",
	"3D Model": "3d_rotation",
	"DSM": "terrain",
	"Orthomosaic": "grid_on",
	"Overlay": "layers",
	"AI Results": "track_changes"
}

export const hasUserSystemRole = (user) => {
	if (!user?.system_role) return false;
	const role = user?.system_role;
	return role === "overseer" || role === "creator" || role === "inspector";
}

@Component({
	selector: "app-model",
	templateUrl: "./model.component.html",
	styleUrls: ["./model.component.scss"],
})
export class ModelComponent {

	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

	@Input("model") set inModel(model: any) {
		this.model = model;
		this.modelDataSource = new MatTableDataSource<any>(model.layers ?? []);
		this.modelType = extractFileExtension(model?.name);
	};
	@Input() project: Project = null;
	@Input() set isProcessing(processing: boolean) {
		this.isLoading = processing;
	};

	@Output() update: EventEmitter<any> = new EventEmitter<any>();

	public modelDataSource: MatTableDataSource<any>;
	public contextMenuPosition = { x: "0px", y: "0px" };
	public shareLink: string = null;
	public expanded: boolean = false;
	public displayedColumns: string[] = [
		"data-type",
		"status",
		"created_at",
		"layer-id",
		"more-options"
	];
	public model: any = null;
	public activeOrg: Organization = null;
	public isLoading = false;
	public modelType: string = "";
	public user: any;
	public hasUserSystemRole = hasUserSystemRole;

	constructor(
		private _alertService: AlertService,
		private _dialog: MatDialog,
		private _modelService: ModelService,
		private _orgService: OrganizationService,
		private _authService: AuthenticationService,
		private _permissionService: PermissionService,
		private _cdr: ChangeDetectorRef,
	) {
		this.user = this._authService.user;
		this._orgService
			.getActiveOrg()
			.subscribe(({ organization }) => {
				this.activeOrg = organization;
			})
	}

	getDataLabel(dataType: string): string {
		return dataLabelObject[dataType]
	}

	checkDataTypeLabel(row) {
		return row.dataTypeLabel !== "AI Results";
	}

	getDataIcon(model): string {
		if (this.hasFailedLayer(model.layers)) return "warning";
		if (model.type === "import") return dataIconEnum.import;
		return "";
	}

	getFileIcon(modelType): string {
		return fileIconEnum[modelType] ?? "";
	}

	openMenu(e): void {
		e.preventDefault();
		this.contextMenuPosition.x = e.clientX + "px";
		this.contextMenuPosition.y = e.clientY + "px";
		this.menuTrigger._handleClick(e);
	}

	openShareLinkDialog(model: any): void {
		if (!this.shareLink && model.public_guid) {
			this.shareLink = makePublicShareLink(model.public_guid);
		}

		const dialogRef = this._dialog.open(ShareLinkComponent, {
			data: {
				createLinkFunc: () => this.createOrModifyShareLink(model),
				setLinkAbilityFunc: (modelId, state) =>
					this.createOrModifyShareLink(model, state),
				selectedShareable: model,
				shareName: model.name,
				shareType: "model",
				project: this.project,
				shareLink: this.shareLink,
				shareState: model.public_share,
			},
		});
		dialogRef
			.afterClosed()
			.pipe(first())
			.subscribe((res) => {
				if (res?.shareLink) {
					this.shareLink = res.shareLink;
					model.public_share = res.shareState;
				}
			});
	}

	async createOrModifyShareLink(model, state?): Promise<string> {
		return this._modelService
			.createOrModifyShare(model.id, state)
			.then((rtnShare) => {
				return makePublicShareLink(rtnShare.public_guid);
			});
	}

	openModelViewer(model_id: number): void {
		if (flagLayer.isEnabled(availableFeatureFlags.disableViewer)) {
			this.openDialog(
				"Feature in maintenance",
				"The Map is currently undergoing maintenance. It will be available as soon as possible.",
				"Got it"
			);
		} else {
			window.open(`#/map/${this.model.project_id}?model=${model_id}`, "_blank");
		}
	} // End-of openModelViewer

	openModelLayer(layer){
		window.open(`#/map/${this.model.project_id}?model=${layer.model_id}&layer=${layer.id}`, "_blank");
	}

	openDialog(title: string, text: string, buttonText: string): void {
		this._dialog.open(ConfirmationModal, {
			data: { title, text, buttonText, showCancel: false },
		});
	}

	renameModel(model): void {
		const dialogRef = this._dialog.open(RenameModal, { data: model });
		dialogRef.afterClosed().subscribe((rtn) => {
			if (rtn) {
				model.name = rtn.name;
				this._modelService
					.update(model)
					.then(() => {
						this._alertService.success(
							new Alert(`Model renamed to ${model.name}.`)
						);
					})
					.catch((err) => {
						console.error(err);
						this._alertService.error(
							new Alert(
								"Model failed to update, please try again."
							)
						);
					});
				analyticsLayer.trackRename(this, "Model");
			}
		});
	}

	renameLayer(layer){

		const dialogRef = this._dialog.open(RenameModal, { data: layer });
		dialogRef.afterClosed().subscribe((rtn) => {
			if (rtn?.name) {
				(layer.dataTypeLabel === "Overlay"
					? this._modelService.createOverlayV2(layer.id, rtn.name)
					: this._modelService.updateLayer(layer))
						.then(() => {
							layer.name = rtn.name;
							this._alertService.success(
								new Alert(`Layer renamed to ${layer.name}.`)
							);
						})
						.catch((err) => {
							console.error(err);
							this._alertService.error(
								new Alert(
									"Layer failed to update, please try again."
								)
							);
						});
			}
		});
	}

	renamePointCloudLayers(layer) {
		if (layer.name === "dense_points.laz" || layer.name === "Pointcloud-dense" || layer.name === "sparse_points.laz" || layer.name === "Pointcloud-sparse") {
			return layer.name = layer.dataTypeLabel;
		} else {
			return layer.name;
		}
	}

	async openExportDialog(model_in) {
		const model = cloneDeep(model_in);
		model.expanded = true;
		model.color = "#005dea"; // blue-500 // --cerulean-blue
		model.layers = [];

		const layerGroup = await loadLayersFromModel(model);

		const data = {
			project: cloneDeep(this.project),
			layerGroups: [layerGroup],
			model_id: model.id,
		};
		this._dialog.open(ExportDialog, { data });
		this._cdr.detectChanges();
	}

	toggleExpand(e) {
		if (this.model.layers?.length) {
			e.preventDefault();
			e.stopPropagation();
			this.expanded = !this.expanded;
		}
	}

	getModelStatus(model): string {
		const { status, statusName, type, layers } = model;
		if (layers.length && (type === "import" || type === "processed")) {
			const hasFailed = this.hasFailedLayer(model.layers);
			return hasFailed ? "Failed" : "" + (layers[0].status ?? "Started");
		}
		return statusName ?? status ?? "Completed";
	}

	hasFailedLayer = (layers) => layers?.some(layer => layer.status === "Failed")

	trashModel(model): void {
		this._modelService.update(Object.assign({}, model, { trash: 1 }))
			.then(() => {
				this._alertService.success(new Alert("Model successfully sent to Trash"));
				this.update.emit("trash");
			})
			.catch(err => {
				console.error(err);
				this._alertService.error(new Alert("Model could not be trashed, please try again later."));
			})
	}

	getModelInfoString(model): string {
		if (model?.layers?.length) {
			return (`${this.getLayerOrigin(model)} ${this.getLayerType(model)}`)
		}
		return model?.status ?? "Uploading...";
	}

	getLayerType(model): string {
		if (model.layers.length === 1) {
			return model.layers[0].dataTypeLabel ?? "Layer";
		}
		return "Layer";
	}

	getLayerOrigin(model): "Mapware" | "Imported" | "Empty" {
		return model.type === "processed" ?
			"Mapware" :
			"Imported";
	}

	isModelEnabled(model): boolean {
		if (isEmpty(model) || isEmpty(model.layers)) return false;
		return model.layers.length &&
			model.layers.some(layer => layer.status === "Rendered");
	}

	isModelImported(modelToCheck): boolean {
		return (modelToCheck.type === "import");
	}

	getPermission(permission: "process" | "admin" | "reader" = "process"): boolean {
		if (!isEmpty(this.activeOrg) && this._authService.user?.id) {
			return this._permissionService.compareToOrgPermissions(this.activeOrg, permission, this._authService.user.id);
		} else {
			return false;
		}
	}

	convertOverlay(overlay, newType) {
		this._modelService.updateOverlayV2(overlay.id, {type: newType}).then(rtn => {
			this._alertService.notify("Overlay successfully converted.", "success");
			overlay.type = rtn[0]?.type ?? newType;
		}).catch(err => {
			console.error(err);
			this._alertService.notify("Overlay failed to convert.", "error");
		});
	}

	isConvertableType(overlay) {
		return overlay.type === 'default' || overlay.type === 'contour' || overlay.type === 'inference-result'
	}
}
