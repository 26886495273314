<div class="map-floating-container map-tools">

	<div class="map-component-box">
		<div class="toolbar-item"  matTooltip="Hand Tool" matTooltipPosition="after"
			[ngClass]="{'selected': activeTool == 'hand'}"
			(click)="activateTool('hand')">
			<mat-icon class="toolbar_item_icon option">
				pan_tool
			</mat-icon>
		</div>

		<ng-container *ngIf="!publicView">
			<div class="toolbar-item" matTooltip="Place Marker" matTooltipPosition="after"
				(click)="activateTool('marker')"
				[ngClass]="{'disabled': !mapPermissions.canAnnotate, 'selected': activeTool == 'marker'}">
				<img class="toolbar_item_icon option"
					src="assets/icons/marker.svg">
			</div>

			<div class="toolbar-item" matTooltip="Draw Line / Shape" matTooltipPosition="after"
				(click)="activateTool('polyline')"
				[ngClass]="{'disabled': !mapPermissions.canAnnotate, 'selected': activeTool == 'polyline'}">
				<img class="toolbar_item_icon option"
					src="assets/icons/link.svg">
			</div>
		</ng-container>
	</div>

	<div class="map-component-box">
		<div class="toolbar-item"
			*featureEnabled="'temp-layer-comparison'"
			[ngClass]="{'selected': map.comparisonModeActivated}"
			(click)="toggleComparisonMode()" matTooltip="Comparison View" matTooltipPosition="after">
			<mat-icon class="toolbar_item_icon option">
				flip
			</mat-icon>
		</div>
		<div class="toolbar-item"
			*featureEnabled="'temp-elevation-view'"
			[ngClass]="{'selected': elevationModeActivated, 'disabled': !hasElevationViewLayers}"
			(click)="toggleElevationMode()" matTooltip="{{getElevationTooltip()}}" matTooltipPosition="after">
			<mat-icon class="toolbar_item_icon option">
				terrain
			</mat-icon>
		</div>
	</div>

	<div class="map-component-box">
		<div class="toolbar-item" matTooltip="Help / Feedback" matTooltipPosition="after"
			[matMenuTriggerFor]="supportMenu"
			[ngClass]="{'selected': activeMenu == 'support'}"
			(click)="selectMenu('support')">
			<mat-icon class="toolbar_item_icon option">
				contact_support
			</mat-icon>
		</div>

		<div class="toolbar-item" matTooltip="Settings" matTooltipPosition="after"
			[matMenuTriggerFor]="settingsMenu"
			[ngClass]="{'selected': activeMenu == 'settings'}"
			(click)="selectMenu('settings')">
			<mat-icon class="toolbar_item_icon option">
				settings
			</mat-icon>
		</div>
	</div>
</div>


<mat-menu #supportMenu class="map-menu-panel support-menu" (closed)="menuClosed()">
	<ng-template matMenuContent>
		<mat-list>
			<mat-list-item *ngIf="supportGuide" (click)="openFAQ()">
				<mat-icon style="margin-right: 16px;">help</mat-icon>
				<span class="H6WhiteRegular">
					Mapware Knowledge Base
				</span>
			</mat-list-item>
			<mat-list-item (click)="sendFeedback()">
				<mat-icon style="margin-right: 16px;">email</mat-icon>
				<span class="H6WhiteRegular">
					Send bug or feedback: <b>{{supportLink}}</b>
				</span>
			</mat-list-item>
		</mat-list>
	</ng-template>
</mat-menu>

<mat-menu #settingsMenu class="map-menu-panel settings-menu" (closed)="menuClosed()">
	<ng-template matMenuContent>
		<div class="menu-option with-sub-options " (click)="$event.stopPropagation();">
			Units
			<mat-radio-group class="" [(ngModel)]="map.selectedUnits">
				<mat-radio-button class="radio-button" *ngFor="let unit of options.DISPLAY_UNITS" [value]="unit" (change)="map.unitsUpdated($event)">
					{{unit.name | titlecase}}
				</mat-radio-button>
			</mat-radio-group>
		</div>

		<div class="menu-option" (click)="$event.stopPropagation();">
			Background Color
			<mat-slide-toggle class="menu-option-input" [checked]="!map.lightBackground"
				(change)="map.toggleBackground();" [ngClass]="map.lightBackground ? 'white-toggled' : 'black-toggled'" hideIcon>
			</mat-slide-toggle>
		</div>

		<div class="menu-option" (click)="$event.stopPropagation();">
			Quality
			<span matTooltipPosition="after" class="menu-info-tip"
				matTooltip="Increasing quality will increase network and device load. This may affect performance.">
				<mat-icon>help_outline</mat-icon>
			</span>
			<mat-select class="menu-option-input wider-input" [(value)]="map.qualitySelection"
				(valueChange)="map.changeQualitySetting()">
				<mat-option *ngFor="let option of options.QUALITY_OPTIONS" [value]="option">
					<span> {{option.name | titlecase}} </span>
				</mat-option>
			</mat-select>
		</div>

		<div class="menu-option" (click)="$event.stopPropagation();">
			Point Size
			<mat-slider #PointSizeRangeRef
				type="range"
				min="1" max="20" disableRipple="true" #ngSlider>
				<input matSliderThumb (valueChange)="updatePointSize($event)" #ngSliderThumb="matSliderThumb" [(value)]="pointSize" />
			</mat-slider>
		</div>

		<div class="menu-option" (click)="$event.stopPropagation();">
			Layer Information
			<mat-slide-toggle class="menu-option-input" [checked]="map.debugDetails"
				(change)="map.toggleDebugDetails();" hideIcon>
			</mat-slide-toggle>
		</div>

		<div class="menu-option" (click)="$event.stopPropagation();">
			Debug 3D Tiles
			<mat-slide-toggle class="menu-option-input" [checked]="map.debug3DTiles"
				(change)="map.toggleDebug3DTiles();" hideIcon>
			</mat-slide-toggle>
		</div>

	</ng-template>
</mat-menu>
