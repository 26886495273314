
/* Imports */
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

/* Models */
import { Project } from '../../../shared/models'

/* Services */
import { ProjectService, GlobalService, ModelService } from '../../../shared/services';
import pluralize from 'pluralize';

@Component({
	selector: 'mat-list-image-item',
	templateUrl: './list-image-item.component.html',
	styleUrls: ['./list-image-item.component.scss']
})

export class AerialListImageItem implements OnInit {

	@ViewChild(MatMenuTrigger) itemMenuTrigger: MatMenuTrigger;
	@ViewChild(MatMenu) itemMenu: MatMenu;

	// Inputs
	@Input('showCreatedAt') showCreatedAt: any;
	@Input('project') set project(proj: Project) {
		if (proj) { this.setup(proj); }
	}
	public _project: Project;
	get project(): Project { return this._project; }

	public contextMenuPosition = { x: '0px', y: '0px' };
	public usePosition: boolean = true;
	// Outputs
	@Output('clicked') click: EventEmitter<any> = new EventEmitter();
	@Output('eventTrigger') eventTrigger: EventEmitter<any> = new EventEmitter();

	public tags: any[] = [];
	public tagsText: string = '';
	public isProcessing: boolean = false;
	public menuOpen: boolean = false;
	public rootURL = GlobalService.databaseApiUrl;
	public defaultImage = '../../../../assets/icons/aa_logo.png';

	public plural = pluralize;

	constructor (
		private _modelService: ModelService,
		private _projectService: ProjectService,
	) {

	}	// End-of constructor

	ngOnInit() {

	}	// End-of OnInit


	setup(project): void {

		const newTags = project.descriptors?.tags || []; // Ensure it's always at least an empty array
		this.tags = this.tags.concat(newTags); // cleanest way to combine the two arrays
		this.tagsText += newTags.slice(0, 3).join(", ");
		this._project = project;

		project.displayThumbnail = this.rootURL + this._project.thumbnail;
	}	// End-of setup

	clickedImage(e): void {

		this.click.emit(e);
		e?.stopPropagation?.();

	}	// End-of clickedImage

	clickedIcon(e, text): void {
		this.eventTrigger.emit({project: this._project, text: text});
		e?.stopPropagation?.();
	}

	resolveImageLoadError(project) {
		project.displayThumbnail = this.defaultImage;
	}

	openItem(e?): void {

		this.click.emit(e);
		e?.stopPropagation?.();

	}	// End-of clickedTitle

	openMenu(e): void {

		if (e) {
			e.preventDefault();
			e.stopPropagation();

			this.menuOpen = true;
			this.contextMenuPosition.x = e.clientX + 'px';
			this.contextMenuPosition.y = e.clientY + 'px';
			this.itemMenuTrigger._handleClick(e);
		}
	}

	contextMenuStyle(): any {
		return {
			left: this.contextMenuPosition.x,
			top: this.contextMenuPosition.y,
		};
	}

	getSharedTooltip = (project) => project['shared_with_user'] ? 'Shared with you' : 'Shared with others';
}	// End-of class ApplistItem
