
/* Imports */
import {Component, Inject} from '@angular/core';
import {
	findUniqueName,
	ImageService,
	isUniqueName,
	ModelService,
	OrganizationService,
	ProjectService
} from "@shared/services";
import {Organization} from "@shared/models";
import pluralize from "pluralize";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {availableFeatureFlags, flagLayer} from "@shared/featureFlags";

interface ProcessingCost {
	organization_id?: number,
	created_by_id?: number,
	quantity?: number,
	processing_balance?: number,
	subscription_balance?: number,
	cost_per_mp?: number,
	cost?: number,
	totalBalance?: number,
	convertedCost?: number
}

const errorOptions = {
	missingGPS: "Some images are missing GPS information.",
	data: "There was an issue getting some information.",
	processingCost: "Failed getting the cost to process, please deselect and reselect.",
	submit: "There was an error submitting the order, please retry and contact support if the issue persists.",
	card: "There was an error with the card associated with your subscription, please check your Account Subscription."
}

@Component({
	selector: 'app-new-process',
	templateUrl: './process.component.html',
	styleUrls: ['./process.component.scss']
})
export class NewProcessComponent {

	public pluralize = pluralize;

	public imageGroups: Array<any>;
	public selectedGroup: any;
	public selectedImageGroups: Array<any>;
	public buttonClass: string = '';
	public project = null;
	public name: string = '';
	public selectedPaymentCard = null;
	public processingCost: ProcessingCost = {};
	public availableCredits: number = 0;
	public selectedImages: number = 0;
	public availableImages: number = 0;

	public organization: Organization;

	public allowsNegativeCredits: boolean = false;
	public isLoading: boolean = true;
	public success: boolean = false;
	public hasPermissions: boolean = false;
	public errorMessage: string = null;

	constructor (
		private _imageService: ImageService,
		private _modelService: ModelService,
		private _projectService: ProjectService,
		private _orgService: OrganizationService,
		private _dialogRef: MatDialogRef<NewProcessComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (data.project?.id) {
			this.isLoading = true;
			Promise.all([
				this.setProject(data.project),
				this.getImageGroups(data.project)
			]).then(() => {
				this.isLoading = false;
			}).catch(err => {
				console.error(err);
				this.isLoading = false;
			})
		}
		this.hasPermissions = data.hasPermissions;
	}

	async setProject(project) {

		this.project = project;
		this.name = await this.makeUniqueName(project);
		await this.getOrgData();
	}

	async getOrgData() {
		this._orgService.get(this.project.organization_id).then(rtnOrg => {
			this.allowsNegativeCredits = !!rtnOrg.allows_negative_credits;
			this.organization = rtnOrg;
			this.availableCredits = Math.round(rtnOrg.subscription_credits + rtnOrg.processing_credits);
		}).catch(err => {
			console.error(err);
			this.errorMessage = errorOptions.data;
		})
	}

	async makeUniqueName(project) {

		const name = `${project.name} model`;
		const models = project.models ?
			project.models : await this.getModels(project.id);

		if (models?.length >= 1) {
			const existingNames = models.map(model => model.name);
			return findUniqueName(name, isUniqueName(existingNames));
		}
		return name;
	}

	async getModels(project_id) {
		return this._modelService.getListV2({project_id}).catch(err => {
			console.error(err);
			this.errorMessage = errorOptions.data;
		})
	}

	async getImageGroups(project = this.project) {
		return this._imageService.getBatchListV2({project_id: project.id}).then(async (rtnBatches) => {
			this.imageGroups = rtnBatches?.filter(x =>
				(!x.trash && !x.sensor)
			) ?? [];
			this.selectAll();

			// get the images for each batch, needed to check for image gps data
			for(let group of this.imageGroups) {
				group.images = await this._imageService.getBatchImagesV2(group.id);
			}

			return this.checkGroups(rtnBatches);
		}).catch(err => {
			console.error(err);
			this.errorMessage = errorOptions.data;
		})
	}

	getTotalImages(imageGroups = this.selectedImageGroups) {
		return imageGroups?.reduce((acc, group) => {
			acc += group.images_count;
			return acc;
		}, 0) ?? 0;
	}

	checkGroups(imageGroups = this.imageGroups) {
		if (imageGroups?.length) {
			imageGroups.forEach(group => {
				group.missingGPSData = !(group.images && !group.images.some(image => !image.latitude || !image.longitude));
			});
			this.availableImages = this.getTotalImages(imageGroups);
			const selected = imageGroups.filter(x => x.selected);
			this.selectedImageGroups = selected;
			this.selectedImages = this.getTotalImages(selected);

			this.errorMessage = null;
			if(this.selectedImageGroups.some(group => group.missingGPSData))
				this.errorMessage = errorOptions.missingGPS;
		}
		return this.getProcessingCost();
	}

	toggleGroup(group) {
		group.selected = !group.selected;
		this.checkGroups();
	}

	async getProcessingCost() {

		this.errorMessage = this.errorMessage || null;

		if (this.selectedImageGroups?.length) {
			this.isLoading = true;
			return this._projectService.getProcessingCost(this.project.id, this.selectedImageGroups.map(x => x.id)).then(rtn => {
				this.processingCost = {
					totalBalance: Math.round(rtn.subscription_balance + rtn.processing_balance),
					convertedCost: (rtn.cost / 100.0).toFixed(2),
					...rtn
				};
				this.isLoading = false;
			}).catch(err => {
				console.error(err);
				this.isLoading = false;
				this.errorMessage = errorOptions.processingCost;
			})
		} else {
			this.processingCost.totalBalance = this.availableCredits;
			this.processingCost.quantity = 0;
			return;
		}
	}

	submitProcess(): void {

		this.errorMessage = null;

		if (!this.getProcessError()) {

			this.isLoading = true;

			const batch_ids = this.imageGroups
				.filter(x => x.selected)
				.map(x => x.id);

			const order = {
				project_id: this.project.id,
				name: this.name,
				batch_ids,
				descriptors: {
					resolution: "high",
					key_points: 50000,
					tie_points: 0,
					coordinate_system: {
						epsg: 4326
					}
				},
				payment_source_id: this.selectedPaymentCard?.payment_source_id,
			};

			const processFunction = flagLayer.isEnabled(availableFeatureFlags.processODM) ?
				this._modelService.createProcessOrderV2(order) :
				this._modelService.createProcessOrder(order);

			processFunction
				.then(rtnData => {
					this.success = true;
					this.isLoading = false;
					this._modelService.setProcess(rtnData.model_id);
					this.getOrgData();
				})
				.catch((err) => {
					console.error(JSON.stringify(err));
					this.isLoading = false;
					this.errorMessage = (err === 402) ?
						errorOptions.card :
						errorOptions.submit;
				});
		}
	}

	checkProcessDisabled() {
		return this.isLoading || this.getProcessError();
	}

	getProcessError() {
		const MATCH_KEY = true as any;
		const matcher = ({
			[MATCH_KEY]: null,
			[(this.selectedImages < 20) as any]: "Select at least 20 images.",
			[!this.selectedImageGroups?.length as any]: "No Image Groups Selected",
			[(this.availableImages < 20) as any]: "You need at least 20 images, please upload more.",
			[(this.name?.length < 1) as any]: "Your Model must have a name.",
			[(!!this.errorMessage as any)]: this.errorMessage,
		})
		return matcher[MATCH_KEY];
	}

	close() {
		this._dialogRef.close();
	}

	reset() {
		this.success = false;
		this.getImageGroups(this.project.id);
	}

	selectAll() {
		this.imageGroups.forEach(group => {
			if (group.images_count) group.selected = true;
		});
		this.checkGroups(this.imageGroups);
	}

	deselectAll() {
		this.imageGroups.forEach(group => {
			group.selected = false;
		});
		this.checkGroups(this.imageGroups);
	}

	toggleAll() {
		if (this.allSelected()) {
			this.deselectAll();
		} else {
			this.selectAll();
		}
	}

	allSelected() {
		return this.imageGroups
			?.filter(group => group.images_count)
			?.every(group => group.selected);
	}

	openUpload() {
		this._dialogRef.close(true);
	}
}
