<ng-container *featureEnabled="'folder-upload'">
	<input data-testid="folderUpload" style="display: none;" type="file" webkitdirectory mozdirectory msdirectory
		odirectory directory multiple #folderInput (change)="folderChanged($event)">
</ng-container>
<input data-testid="dialogUpload" style="display: none;" multiple type="file" #fileInput
	(change)="filesChanged($event.target.files)">

<div class="confirmation-container" data-testid="confirmation-modal">
	<div class="main-header">
		<div class="header-title">
			Upload
		</div>
		<button mat-icon-button class="close-button" matTooltip="Close" (click)="close()">
			<mat-icon>close</mat-icon>
		</button>
	</div>

	<div class="parent-container">

		<ng-container *ngTemplateOutlet="t_detected"></ng-container>

		<div class="child-container">
			<ng-container *featureEnabled="'show-upload-minimap'">
				<ng-container *ngTemplateOutlet="t_minimap"></ng-container>
			</ng-container>

			<ng-container *ngIf="uploadItems.length">
				<ng-container *ngTemplateOutlet="t_showItems"></ng-container>
			</ng-container>

			<ng-container *ngIf="!uploadItems.length">
				<ng-container *ngTemplateOutlet="t_upload"></ng-container>
			</ng-container>
		</div>

		<ng-container *ngIf="uploadItems.length">
			<ng-container *ngTemplateOutlet="t_confirmationButtons"></ng-container>
		</ng-container>
	</div>
</div>

<ng-template #t_detected>
	<div class="upload-label" *ngIf="uploadItems.length">
		<p class="detected phone-hidden">We've detected <b>{{ uploadItems.length }} {{setPlural("file", uploadItems.length)}}</b>
			and {{ makeIdentifyString(uploadItemsByType, uploadItems.length) }}
			<b>{{ makeUploadItemsByTypeString(uploadItemsByType, uploadItems.length !== 1) }}.</b>
		</p>
		<span class="links-container">
			<div class="link-container" (click)="openUploadInput()">
				<span class="link">
					Select More
				</span>
				<mat-icon>add</mat-icon>
			</div>
			<div class="link-container" *featureEnabled="'folder-upload'" (click)="folderInput.nativeElement.click()">
				<span class="link xs">
					Upload Folders
				</span>
			</div>
		</span>
	</div>
</ng-template>

<ng-template #t_minimap>
	<span class="flex-column-center tablet-hidden">
		<span class="upload-tabs H6Black700Semibold">
			Images with GPS
			<ng-container *ngIf="minimapValues?.length">
				({{ (minimapValues?.length >= 50 ? "showing first " : "") + minimapValues?.length }})
			</ng-container>
		</span>
		<div class="minimap-container">
			<app-minimap [positions]="minimapValues"></app-minimap>
		</div>
	</span>
</ng-template>

<ng-template #t_showItems>
	<span class="uploaded-content">
		<div class="upload-tabs">
			<ng-container *ngFor="let itemString of uploadItemsStrings">
				<div (click)="setTabType(itemString)" *ngIf="uploadItemsByType[itemString]?.length"
					[ngClass]="{'active': isTabType(itemString)}">
					{{uploadItemsByType[itemString]?.length}} {{ setPlural(itemString,
					uploadItemsByType[itemString]?.length) }}
				</div>
			</ng-container>
		</div>
		<aerial-dropzone class="upload-dropzone" (filesEmitter)="dropzoneUpload($event)">
			<div class="uploaded-container">
				<ng-container *ngTemplateOutlet="t_batchSelect"></ng-container>
			</div>
		</aerial-dropzone>
	</span>
</ng-template>

<ng-template #t_upload>
	<span class="uploaded-content">
		<aerial-dropzone (filesEmitter)="dropzoneUpload($event)">
			<div class="upload-container">
				<ng-container *ngTemplateOutlet="t_standardDropzone"></ng-container>
			</div>
		</aerial-dropzone>
	</span>
</ng-template>

<ng-template #t_batchSelect>
	<form style="display: contents;" *ngIf="batchForm && uploadItems?.length" [formGroup]="batchForm">

		<div class="upload-item-list">
			<virtual-scroller #scroll [items]="uploadItemsByType[tabShowing]">
				<ng-container *ngFor="let uploadItem of scroll.viewPortItems"
					[ngTemplateOutlet]="t_uploadItem"
					[ngTemplateOutletContext]="{ uploadItem }"></ng-container>
			</virtual-scroller>
		</div>

		<div class="upload-options-row">
			<div class="upload-text">
				<mat-icon>keyboard_double_arrow_down</mat-icon>
				<ng-container *ngFor="let uploadType of uploadTypes">
					<div *ngIf="isTabType(uploadType.type)" [matTooltip]="uploadItemTooltips[uploadType.type]">
						{{ uploadType.text }}
						{{ uploadType.varText ? uploadType.varText(uploadItemsByType[tabShowing]?.length) : "" }}
						<b>{{ uploadType.boldText(uploadItemsByType[tabShowing]?.length) }}</b>
						<mat-icon *ngIf="uploadItemTooltips[uploadType.type]">help</mat-icon>
					</div>
				</ng-container>
			</div>
		</div>

		<ng-container *ngIf="isTabType('Map Layer') && isDeveloper && hasVectorFiles">
			<div class="side-option">
				Vector Data:
				<mat-form-field appearance="fill" class="vector-selection">
					<mat-select [(value)]="selectedVectorOption">
						<mat-select-trigger>
							{{ selectedVectorOption.header }}
						</mat-select-trigger>
						<ng-container *ngFor="let option of vectorOptions">
							<mat-option class="custom-option" [value]="option">
								<b>{{ option.header }}</b>
								<p>{{ option.text }}</p>
							</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
		</ng-container>

		<div class="image-group-options" *ngIf="isTabType('Image')">
			<mat-form-field appearance="fill" class="batch-name"
				[ngClass]="{'existing': batchForm.value?.batchControl?.id, 'error': anyErrorsActive()}">
				<mat-label>Image Group Name</mat-label>

				<input type="text" matInput placeholder="Search for an existing group or make a new one"
					formControlName="batchControl" [matAutocomplete]="auto">
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="formatSelectedBatch">
					<mat-option *ngFor="let opt of batchOptions | async" [value]="opt" (onSelectionChange)="onSelectChange()">
						<div class="option-content">
							<span class="H7Black700Semibold">{{ opt.name }}</span>
							<span>
								<span class="P1Black500">
									{{ opt.sensor ? "Multispectral Group" : "Image Group" }}
									- Created On {{ opt.created_at | date }}
								</span>

							</span>
						</div>
					</mat-option>
				</mat-autocomplete>

				<span class="batch-select-actions" *ngIf="batchForm.value?.batchControl?.name">
					<span class="H7Black500Regular">{{ batchForm.value.batchControl.id ? getExistingGroupText(batchForm.value.batchControl) : "New Group" }}</span>
					<button (click)="clearSelection($event, 'batch');" mat-icon-button matTooltip="Clear">
						<mat-icon>close</mat-icon>
					</button>
				</span>
			</mat-form-field>

			<div class="name-error">

				<ng-container *ngIf="batchForm.controls.batchControl.errors?.maxLength">
					<mat-error class="H7RedRegular">Image Group name is too long. Please choose a shorter
						name.</mat-error>
				</ng-container>

				<ng-container *ngIf="batchForm.controls.batchControl.errors?.nameMissing">
					<mat-error class="H7RedRegular">Please enter a name for the new Image Group.</mat-error>
				</ng-container>

				<ng-container *ngIf="!hasUniqueName && !isExistingGroup" class="name-error">
					<mat-error class="H7RedRegular">Image Group name already exists. <br> Please choose a unique name or
						select that group in the dropdown.</mat-error>
				</ng-container>

				<ng-container *ngIf="!multispectralImages">
					<ng-container  *ngIf="!anyErrorsActive()">
						<mat-error *ngIf="!isExistingGroup" class="H7Black500Regular">Upload to a new group, or search for
							an existing one.</mat-error>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="multispectralImages">
					<mat-error *ngIf="!anyErrorsActive() && !isExistingGroup" class="H7Black500Regular">Upload multispectral images to
						{{batchForm.value.batchControl?.id ? 'an existing' : 'a new'}}
						group.</mat-error>

					<ng-container *ngIf="!multispectralSensor.length">
						<mat-error class="H7RedRegular">Please select the multispectral sensor.</mat-error>
					</ng-container>

				</ng-container>
			</div>

			<div class="side-option" [ngClass]="{'disabled': isExistingGroup}">
				<mat-checkbox class="aerial-font" (change)='toggleMultispectralImages()'
							  [checked]="multispectralImages" [labelPosition]="'before'">
					Multispectral
				</mat-checkbox>

				<div *ngIf="isTabType('Image') && multispectralImages">
					<mat-label class="aerial-font">Sensor</mat-label>
					<mat-form-field class="smaller-input">
						<mat-select class="aerial-font" [(value)]="multispectralSensor">
							<mat-option *ngFor="let sensor of multispectralSensorsList" [value]="sensor.sensor">
								{{ sensor.sensor | titlecase }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
	</form>
	<form style="display: contents;" *ngIf="modelForm && uploadItems?.length" [formGroup]="modelForm">
		<div class="image-group-options" [ngClass]="{'hidden': !isTabType('Map Layer')}">
			<mat-form-field appearance="fill" class="batch-name"
				[ngClass]="{'existing': modelForm?.value?.modelControl?.id, 'error': anyErrorsActive()}">
				<mat-label>Model Name</mat-label>

				<input type="text" matInput placeholder="Search for an existing Model"
					formControlName="modelControl" [matAutocomplete]="auto">
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="formatSelectedModel">
					<mat-option *ngFor="let model of modelOptions" [value]="model" (onSelectionChange)="onSelectChange(model)">
						<div class="option-content">
							<span>{{ model.name }}</span>
						</div>
					</mat-option>
				</mat-autocomplete>

				<span class="batch-select-actions" *ngIf="modelForm?.value?.modelControl">
					<span class="H7Black500Regular">{{ modelForm.value.modelControl.id ? "Existing Model" : "New Model" }}</span>
					<button (click)="clearSelection($event, 'model');" mat-icon-button matTooltip="Clear">
						<mat-icon>close</mat-icon>
					</button>
				</span>
			</mat-form-field>
		</div>
	</form>
</ng-template>

<ng-template #t_standardDropzone>
	<div class="upload-icon">
		<mat-icon>
			cloud_upload
		</mat-icon>
	</div>

	<div class="dropzone-text">
		Drag and drop or <span class="link" (click)="openUploadInput()">upload your files here</span> and we’ll get them
		in the right place.
	</div>

	<div *featureEnabled="'folder-upload'">
		<span class="xs"> You can also </span>
		<span class="link xs" (click)="folderInput.nativeElement.click()">
			upload entire folders.
		</span>
	</div>
</ng-template>

<ng-template #t_confirmationButtons>
	<div class="dialog-buttons">
		<div class="primary-buttons">
			<button *ngIf="uploadItems.length" mat-button class="H6Black700Regular" (click)="clearFolderUpload()">
				Back
			</button>
			<button mat-flat-button color="primary" class="H6WhiteRegular" [disabled]="anyErrorsActive()"
				(click)="submitUpload()">
				Upload
			</button>
		</div>
	</div>
</ng-template>


<ng-template #t_uploadItem let-uploadItem='uploadItem'>
	<div class="upload-item" *ngIf="uploadItem?.file">
		<div class="upload-name">
			<p matTooltip="{{uploadItem?.file?.name}}">
				{{ uploadItem?.file?.name }}
			</p>
		</div>
		<span class="upload-item-actions">
			<p class="H7Black500Regular">{{ uploadItem?.file?.size | bytes }}</p>
			<ng-container *ngIf="!isLoading && isImageTab(tabShowing) && !imageItemHasLocation(uploadItem)">
				<span *ngIf="!fileTooBigToParse(uploadItem)"
					  class="error-button"
					  matTooltip="Image lacks GPS data and can prevent processing">
					<mat-icon>warning</mat-icon>
				</span>
				<span class="size-button"
					  *ngIf="fileTooBigToParse(uploadItem)"
					  matTooltip="Image is too large to parse GPS data now, we'll get it after the file is uploaded.">
					<mat-icon>info</mat-icon>
				</span>
			</ng-container>
			<button *ngIf="isSameTypeAsTab(uploadItem) && tabShowing !== 'File'"
					(click)="moveItemToFiles(uploadItem)" mat-icon-button matTooltip="Move to Files">
				<span class="move-button">
					<mat-icon>description</mat-icon>
					<span class="mini-icon"><mat-icon>arrow_forward</mat-icon></span>
				</span>
			</button>
			<button *ngIf="!isSameTypeAsTab(uploadItem)" (click)="moveItemBack(uploadItem)" mat-icon-button [matTooltip]="moveBackTooltip(uploadItem)">
				<span class="move-button">
					<mat-icon>description</mat-icon>
					<span class="mini-icon"><mat-icon>arrow_back</mat-icon></span>
				</span>
			</button>
			<button (click)="removeItem(uploadItem)" mat-icon-button matTooltip="Remove">
				<mat-icon>close</mat-icon>
			</button>
		</span>
	</div>
</ng-template>
