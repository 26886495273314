
/* Imports */
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Component, ViewChild, Input, Output, EventEmitter, OnChanges, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from "@angular/material/sort";
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { isEmpty } from 'lodash';

/* Services */
import { FavoriteService, GlobalService, PermissionService, AuthenticationService, OrganizationService } from 'src/app/shared/services';
import { Organization } from 'src/app/shared/models';
import { Subscription } from 'rxjs';

export interface RowDataTemplate {
	id: string;
	filename: string;
	size: string;
	date_modified: string;
}

@Component({
	selector: 'app-main-table',
	templateUrl: './main-table.component.html',
	styleUrls: ['./main-table.component.scss']
})
export class AerialMainTable implements OnChanges {

	@Input() tableDataSource = new MatTableDataSource(); // changes to tableDataSource will be handled in ngOnChanges to capture more than just  the top level `set`

	get pageIsFavorites() {
		return this.currentPage === 'favorites';
	}
	get pageIsSearch() {
		return this.currentPage === 'search';
	}
	get pageIsTrash() {
		return this.currentPage === 'trash';
	}

	@Input('isProcessing') isProcessing: boolean = false;
	@Input('emptyText') emptyText: string;
	@Input('displayedColumns') displayedColumns: Array<any> = ['name'];
	@Input('selection') selection = new SelectionModel<RowDataTemplate>(true, []);
	@Input() currentPage: string;

	@Output('outEvent') outEvent: EventEmitter<any> = new EventEmitter();

	@ViewChild(MatSort) set sort( s: MatSort ) {
		if ( s ) { this.dataSource.sort = s; }
	};
	@ViewChildren(MatMenu) menuStates: QueryList<MatMenu>;
	@ViewChildren(MatMenuTrigger) menuTriggers: QueryList<MatMenuTrigger> = new QueryList();

	public dataSource: MatTableDataSource<RowDataTemplate> = new MatTableDataSource();
	public defaultMenuTriggers: Array<any> = [];
	public sortedData: Array<any> = null;
	public rootURL = GlobalService.databaseApiUrl;
	public isActive: Array<boolean> = [];
	public activeItem: number;
	public dataSubscription: Subscription;
	public activeOrg: Organization = null;
	public contextMenuPosition = { x: '0px', y: '0px' };

	public dataValues = [
		{value: 'image', text: 'Image', icon: 'camera_alt'},
		{value: 'project', text: 'Project', icon: 'folder_open'},
		{value: 'model', text: 'Model', icon: 'panorama'},
		{value: 'project_file', text: 'File', icon: 'assignment'},
		{value: 'export', text: 'Export', icon: 'collections'},
		{value: 'batch', text: 'Image Group', icon: 'perm_media'},
	];

	public menuData: {name: string, id: number} = { name: null, id: null };

	constructor (
		private _favoriteService: FavoriteService,
		private _router: Router,
		private _cdr: ChangeDetectorRef,
		private _authService: AuthenticationService,
		private _permissionService: PermissionService,
		private _orgService: OrganizationService,
	) {
		this._orgService
			.getActiveOrg()
			.subscribe(({ organization }) => {
				this.activeOrg = organization;
			})
	}	// End-of constructor

	ngOnChanges(changes) {
		const { tableDataSource } = changes;
		// This lets us watch for changes to dataSource.data, not just dataSource itself
		if ( tableDataSource?.currentValue?.data ) {
			this.handleData(tableDataSource?.currentValue);
		}
	}

	ngOnDestroy() {
		this.dataSubscription.unsubscribe();
	}

	handleData(value): void {

		this._cdr.detectChanges();

		if (this.pageIsSearch) {
			this.handleFavorites(value.data);
		}

		this.isActive = [];
		this.dataSource.data.forEach(x => {
			this.isActive.push(false);
		})
		this.dataSource = value;
		this.defaultMenuTriggers = null;

		this.dataSubscription = this.dataSource.connect().subscribe(val => {
			this.sortedData = val;
			this._cdr.detectChanges();
		});
	}


	handleFavorites(data: Array<any>): void {
		this._favoriteService.getList().then(favList => {
			favList = Object.values(favList).reduce( (acc: Array<any>, curVal) => {
				return acc.concat(curVal);
			}, []);
			favList.forEach(fav => {
				let index: number;
				if (fav.project_id) {
					index = data.findIndex(item => item.id === fav.project_id);
				} else if (fav.image_id) {
					index = data.findIndex(item => item.id === fav.image_id);
				} else if (fav.model_id) {
					index = data.findIndex(item => item.id === fav.model_id);
				} else if (fav.project_file_id) {
					index = data.findIndex(item => item.id === fav.project_file_id);
				} else {
				}
				if (index > -1) {
					data[index]['favorited'] = true;
					data[index]['favorited_id'] = fav.id;
				}
			});
		});
	}

	handleImageLoadError(event) {
		event.target.src = '../../../assets/images/broken_image.png';
	}

	checkFavorited(row): boolean {
		return row.favorited || this.pageIsFavorites;
	}

	handleAction(item, type): void {
		if (item && type) {
			if (!(this.pageIsTrash && type === 'open')) {
				this.outEvent.emit({item: item, type: type, data: this.sortedData})
			} else {
				this.selection.toggle(item);
			}
		}
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}	// End-of isAllSelected


	/** Selects all rows if they are not all selected; otherwise clear selection. */
	selectAll(): void {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.data.forEach(row => this.selection.select(row));
	}	// End-of selectAll


	/** The label for the checkbox on the passed row */
	selectRow(row?: RowDataTemplate): string {

		if (!row) {
			return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;

	}	// End-of selectRow


	openMenu(e, i, rowData): void {
		if (this.menuTriggers) {
			e.preventDefault();
			this.contextMenuPosition.x = e.clientX + 'px';
			this.contextMenuPosition.y = e.clientY + 'px';
			this.menuData = rowData;
			this.activeItem = i;
			this.menuTriggers.toArray()[i]._handleClick(e);
			this._cdr.detectChanges();
		}
	}

	getDisplayValue(value: string, type: string): string {
		return this.dataValues.find(x => x.value === value)?.[type];
	}

	hasPermissions(): boolean {
		if (!isEmpty(this.activeOrg)) {
			return this._permissionService.compareToOrgPermissions(this.activeOrg, "process", this._authService.user.id);
		}
	}
}	// End-of class ApplistItem
