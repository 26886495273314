export const environment = {
	envName: "staging",
	production: true,
	apiUrl: "https://dab-api.staging.mapware.com",
	chargebee: { site: "aerialapplications-test", publishableKey: "test_MmGFfAcnKPyqpvGWVIMlQO4y5ZgYG0S4" },
	mapbox: "pk.eyJ1IjoiYWVyaWFsYXBwbGljYXRpb25zIiwiYSI6ImNqNHI1cDdhYTJoMXoyd3FzdGV4djI4aGEifQ.9hnyl8MAor2RuAQvvmDj-g",
	googleAuthClientID: "1004473335106-u5g999h0jc80utudfg5gd34v1v9nf64n.apps.googleusercontent.com",
	googleAPI: "AIzaSyChazFnIjyrR3M6rdVMSKityi6BDnVzLls",
	freeCredits: 10000,
	supportEmail: "support@mapware.com",
	supportGuide: "https://resources.mapware.com/knowledge",
	termsOfService: "https://mapware.com/terms/mapware/",
	privacyPolicy: "https://mapware.com/privacy-policy/",
	cookiePolicy: "https://mapware.com/cookie-policy/",
	legal: "https://mapware.com/legal/",
	googleTagManager: "GTM-KHQD67X",
	googleMeasurementID: "G-2TZWKYH4BJ",
	launchDarklyClientSideId: "61e191e0b486ac163cb846ac",
	launchDarklySDKKey: "sdk-f798f9e7-3af1-4cca-926b-0c89d2b40cf9"
};
