import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";

import { OrthoTile } from "../../viewer/ortho.viewer.component";

import {
	sortByNewestToOldest,
	shouldShowLayer,
	getGoodLayerName,
	LAYER_FILE_TYPE} from "./map.utils";

const isValidGeo = (geo: any): boolean =>
	(geo.north || geo.south) &&
	(geo.east || geo.west) &&
	geo.url &&
	geo.zoom &&
	geo.minZoom;

const maybePadBoundaries = (boundaries): any => {
	if (flagLayer.isEnabled(availableFeatureFlags.extendGeoTIFFBoundaries)) {
		if (boundaries.north === boundaries.south) {
			boundaries.north += 0.0004;
			boundaries.south -= 0.0004;
		}
		if (boundaries.east === boundaries.west) {
			boundaries.east -= 0.0004;
			boundaries.west += 0.0004;
		}
	}
	return boundaries;
};

export const parseLayersFromGeotiffs = (model) => {
	const geotiffs = model.geotiffs.sort(sortByNewestToOldest);
	geotiffs.forEach((geo) => {
		if (isValidGeo(geo) && shouldShowLayer(geo)) {
			let type = geo.type;

			// Type Renaming
			if (type === "dem") type = "dsm";

			const boundries = maybePadBoundaries({
				east: geo.east,
				north: geo.north,
				south: geo.south,
				west: geo.west,
			});

			const newLayer = {
				boundries: boundries,
				createdBy: geo.created_by_id,
				createdOn: geo.created_at,
				errors: {},
				fileType: LAYER_FILE_TYPE.GEOTIFF,
				id: "g" + geo.id,
				mapType: "2D",
				maxZoom: geo.zoom,
				minZoom: geo.minZoom,
				model_id: model.id,
				name: getGoodLayerName(geo.name ?? geo.type),
				showDetails: false,
				source: geo.source,
				status: geo.status,
				opacity: 1,
				// TODO - set layer name from data - UX-204
				type: type,
				url: geo.url,
				visible: false,
			} as OrthoTile;
			model.layers.push(newLayer);
		}
	});
	return model;
};
