
<div class="process-container box" *ngIf="project?.id">

	<div class="main-header">
		<span class="header-title">
			Process New Model
		</span>
		<button class="close-button" (click)="close()" mat-icon-button matTooltip="Cancel Process">
			<mat-icon> close</mat-icon>
		</button>
	</div>

	<ng-container *ngIf="!success">

		<div class="process-content">
			<ng-container *ngIf="!selectedGroup?.id">
				<ng-container *ngTemplateOutlet="t_main"></ng-container>
			</ng-container>
			<ng-container *ngIf="selectedGroup?.id">
				<ng-container *ngTemplateOutlet="t_images"></ng-container>
			</ng-container>
		</div>

		<ng-container *ngIf="!selectedGroup?.id">
			<ng-container *ngTemplateOutlet="t_actions"></ng-container>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="success">
		<div class="success">
			<h3>Your Model is Processing.</h3>
			<p>You will get an email confirmation once your model has been successfully generated.</p>
			<div>
				<button class="link" (click)="close()" mat-button>
					Close
				</button>
				<button mat-flat-button (click)="reset()" color="primary">
					Submit Another
				</button>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #t_main>
	<div class="step">
		<div class="step-block">
			<h4>
				Step 1: Select Image Groups
			</h4>
			<div>
				<div class="group-list">
					<ng-container *ngIf="!isLoading">
						<ng-container *ngIf="imageGroups?.length">
							<ng-container *ngTemplateOutlet="t_imageGroups"></ng-container>
						</ng-container>
						<ng-container *ngIf="!imageGroups?.length">
							<div class="link-container" (click)="openUpload()">
								<mat-icon style="color:#005dea">add_circle_outline</mat-icon>
								<span class="link">Upload Images</span>
							</div>
						</ng-container>
					</ng-container>
				</div>
				<div class="selection">
					<span *ngIf="imageGroups?.length"
						  class="P2Black500"
						  (click)="toggleAll()">
						{{allSelected() ? "Deselect" : "Select"}} All
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class="step">
		<div class="step-block">
			<h4>
				Step 2: Name Your Model
			</h4>
			<mat-form-field appearance="outline">
				<input matInput [(ngModel)]="name" maxlength="64" autofocus>
			</mat-form-field>
		</div>

		<div class="step-block">
			<h4>
				Step 3: Confirmation
			</h4>
			<div>
				<div class="confirmation">
					<div [ngClass]="{ 'error': !isLoading && !selectedImageGroups?.length }">
						<div>Image Groups:</div> {{ selectedImageGroups?.length ?? 0 }}
					</div>
					<div [ngClass]="{ 'error': !isLoading && (selectedImages < 20) }">
						<div><b> Images:</b></div> <b>{{ selectedImages ?? 0 }}</b>
					</div>
					<mat-divider></mat-divider>
					<div>
						<div>Available Credits:</div>
						<p> {{ availableCredits ?? 0 | comma }} credits </p>
					</div>
					<div>
						<div>Cost:</div>
						<b> {{ 0 - (processingCost.quantity ?? 0) | comma }} credits </b>
					</div>
					<div>
						<div><b>Remaining Credits:</b></div>
						<b> {{ processingCost.totalBalance ?? 0 | comma }} credits </b>
					</div>
					<ng-container *ngIf="(processingCost.totalBalance < 0)">
						<div class="cost">
							<p class="H6Black700Semibold bold">Cost to Purchase: ${{ processingCost.convertedCost ?? 0 }}</p>
						</div>
						<p class="error-item">
							<ng-container *ngIf="!checkProcessDisabled()">
								<ng-container *ngIf="allowsNegativeCredits">Your Organization allows Negative Credits </ng-container>
								<ng-container *ngIf="!allowsNegativeCredits">Insufficient credits, additional credits will be charged to your primary payment method.</ng-container>
							</ng-container>
							<span class="error" *ngIf="!isLoading && checkProcessDisabled()">
								{{ getProcessError() }}
							</span>
						</p>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #t_images>
	<span class="images-header">
		<button matTooltip="Back to Process" (click)="selectedGroup = null;" mat-button><mat-icon>arrow_back</mat-icon></button>
		<div class="H5Black700">
			{{ selectedGroup.name }}
		</div>
		<p>({{ selectedGroup.images_count }} images)</p>
	</span>
	<div class="images-container">
		<app-images [showInfo]="false"
					[hasPermissions]="hasPermissions"
					[imageGroup]="selectedGroup"
					listType="small">
		</app-images>
	</div>
</ng-template>

<ng-template #t_actions>
	<div class="actions-container">
		<div>
			<button class="link" (click)="close()" mat-button>
				Cancel
			</button>
			<button [disabled]="checkProcessDisabled()" (click)="submitProcess()" mat-flat-button color="primary">
						<span *ngIf="isLoading" class="loading-container">
							<span class="dot-stretching"></span>
						</span>
				<ng-container *ngIf="!isLoading">Submit Process</ng-container>
			</button>
		</div>
	</div>
</ng-template>

<ng-template #t_imageGroups>
	<ng-container *ngFor="let group of imageGroups">
		<div class="group-item" [ngClass]="{'disabled': !group.images_count}" (click)="toggleGroup(group); $event.stopPropagation()">
			<mat-checkbox
				(click)="$event.stopPropagation()"
				(change)="checkGroups()"
				[(ngModel)]="group.selected"></mat-checkbox>
			<span>
				<div class="H7Black700Semibold">{{ group.name }}</div>
				<p>{{ group.images_count ?? 0 }} Images</p>
			</span>
			<mat-icon *ngIf="group.missingGPSData" matTooltip="Images lack GPS data">warning</mat-icon>
			<span
				class="group-expand"
				matTooltip="Open Image Group"
				(click)="selectedGroup = group">
				<mat-icon>open_in_full</mat-icon>
			</span>
		</div>
	</ng-container>
</ng-template>
